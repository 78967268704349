export const API_VERSION = {
  V1: "/v1",
};

export const URLS = {
  deleteUserAccount: "/user/detele", 
  userLogin: "/user/login",
  userSignUp: "/user/signup",
  userLogOut: "/user/logout",
  ChangePassword: "/user/changePassword",
  userforgetPassword: "/user/forgetPassword",
  userupdatePassword: "/user/forgetChangePassword",
  useruploadprofile: "/user/profileUpload",
  userupdateprofile: "/user/profileUpdate",
  userupdateprofileLocation: "/user/locationUpdate",
  userfetchdata: "/user/userGet",
  getAllFilters: "/search/allmaster",
  getDataFromFriendAndGroup: "/search/getShare",
  userroleget: "/getAllUserRole?status=enable",
  usergetallgradelevel: "/master/getAllGradeLevel?status=enable",
  usergetalleducationcourses: "/master/getAllEducationalCourses?status=enable",
  usergetalllearningstyle: "/master/getAllLearningStyle?status=enable",
  userchildprofileupload: "/child/childProfileUpload",
  userchildprofileupdate: "/child/updateProfileChild",
  usergetallspeciallearning: "/master/getAllSpecialLearning?status=enable",
  userchildupdateprofile: "/child/updateProfileChild",
  getAllChildData: "/getAllChildList",
  childLearningStype: "/child/getAllChildLearningStyle",
  addChildLearningStype: "/child/addChildLearningStyle",
  getAllTopicSubject: "/getAllTopicSubject?status=enable",
  getAllTopicCtegory: "/getAllTopicCategory?status=enable",
  getAllInstructionalStyle: "/master/getAllInstructionalStyle?status=enable",
  deleteChild: "/child/deleteChild",

  chatGPTSearchCompletions: "https://api.openai.com/v1/completions",

  // for chat
  getChatMemberListToAddApi: "/chat/getChatMemberListToAdd",
  addChatUser: "/chat/addChatUser",
  addMultiplechatUser: "/chatMultiple/saveChatGroup",
  updateMultiplechatUser: "/chatMultiple/updateChatGroup",
  getChatGroup: "/chat/getChatGroup",
  saveChatGroup: "/chat/saveChatGroup",
  getChatWithGroupUser: "/chat/getChatWithGroupUser",
  getChatWithGroupUserSidebar: "/chat/getChatWithGroupUserSidebar ",
  uploadGroupImage: "/chat/uploadGroupImage",
  UploadMultiChatImage: "/chatMultiple/imageGroupUpload",
  deleteChatGroup: "/chat/deleteChatGroup",
  addChatGroupMember: "/chat/addChatGroupMember",
  saveChatSound: "/chat/saveChatSound",
  addChatMsg: "/chat/addChatMsg",
  getChatMsg: "/chat/getChatMsg",
  getMultiChat: "/chatMultiple/getGroupChatMsg",
  singleChatDeleteInMultiChat: "/chatMultiple/deleteSingleChat",
  addGroupChatMsg: "/chat/addGroupChatMsg",
  addMultiChat: "/chatMultiple/addChatMsg",
  getGroupChatMsg: "/chat/getGroupChatMsg",
  getChatGroupMember: "/chatMultiple/getMemberChatGroup",
  leaveBloackChatGroup: "/chat/leaveBloackChatGroup",
  leaveMultiChat: "/chatMultiple/leaveGroup",
  blockUnblockChat: "/chat/blockUnblockChat",
  markAllAsRead: "/chat/markAllAsRead",
  multiChatReadAll: "/chatMultiple/markAllAsRead",
  deleteInMultiChat: "/chatMultiple/deleteChatGroup",
  deleteSingleChat: "/chat/deleteSingleChat",
  getBlockedChatGroupList: "/chat/getBlockedChatGroupList",
  deleteBulkChat: "/chat/deleteBulkChat",
  addChatImage: "/chat/addChatDoc",
  Announcement: "/chat/addGroupAnnouncement",
  reportResource: "/user/resource/createResourceOffensive",
  saveResource: "/user/resource/saved",
  pendingMemberInvite: "/group/getMemberPending",
  getAdminUser: "/group/getMemberAdmin",
  nonMatchedInvite: "/group/getMemberPendingInvaite",
  getParentChilds: "/getAllChildList",
  getSaveResourceContent: "/save/resource/content",
  getServeResourceContent: "/serve/resource/content",
  deleteSaveResourceContentFile: "/save/resource/deleteResourceFile",
  deleteServeResourceContentFile: "/serve/resource/deleteResourceFile",
  addGroupChatImage: "/chat/addGroupChatDoc",
  addDocInMultiChat: "/chatMultiple/addChatDoc",
  getResourceReviews: "/user/resource/review/getAll",
  addResourceReview: "/user/resource/review",
  V1: "/v1",
  getAllResources: "/master/getAllResourceType?status=enable",
  getAllLearningPillar: "/master/getLearningPillar?status=enable",
  getAllStates: "/master/getAllStates",
  saveResources: "/save/resource/add",
  // getChilds: "/getAllChildList",
  removefile: "/save/resource/removefile",
  getFundingType: "/master/getAllFundingType?status=enable",
  getOrganizationType: "/master/getAllTypeOrganisation?status=enable",
  createAddInviteFriend: "/user/friend/addInviteFriend",
  friendSearch: "/user/friendSearch",
  friendInvite: "/user/friendInvite",
  ListOfPendingFriendRequest: "/user/friend/myFriendPendingList",
  myFriendEmailPendingList: "/user/friend/myFriendEmailPendingList",
  myFriendList: "/user/friend/myFriendlist",
  myFriendListForShare: "/share/friend/myFriendlist",
  acceptOrRejectRequestFriend: "/user/friend/myFriendStatus",
  followList: "/user/friend/followerList",
  profileImageDelete: "/user/profileImageDelete",
  childImageDelete: "/child/childProfileDeleted",
  newFriendRequest: "/user/friend/newFriendRequest",
  getFacilityType: "/master/getAllFacilityType?status=enable",
  getExperienceType: "/master/getAllTypeExperience?status=enable",
  getSupportType: "/master/getAllTypeSupport?status=enable",
  shareChildProfilePermission: "/child/shareChildProfilePermission",
  getChildWithPermission: "/child/getChildWithPermission",
  // removeChildProfilePermission: "/child/removeChildProfilePermission",
  shareWithFriendsToggle: "/user/resource/viewable/byfriends",
  createChatGptResource: "/search/resource/CreateChatGpt",
  getchildresource: "/user/resource/saved/getChildSavedResources",
  getSaveResourceById: "/serve/resource/getOneResourceNew",
  getOneResourceDetails: "/serve/resource/getOneResourceDetails",
  getOneResourceMentoring: "/serve/resource/getOneResourceMentoring",
  checkFileSize: "/save/resource/checkFileSize",
  getServeResourceById: "/serve/resource/getOneResourceNew",
  UploadSave: "/save/resource/file/UploadSave",
  updateResources: "/save/resource/update",
  getSavedChildResources: "/user/resource/saved/getChildSavedResources",
  getonechild: "/child/getOneChild",
  getPermissionChild: "/child/getAllChildProfileShare",
  getMySavedResources: "/save/resource/saved/getMySavedResources",
  deleteSavedResources: "/save/resource/deleteUserResource",
  deleteServeResources: "/serve/resource/deleteUserResource",
  sendFriendRequestWithChild: "/user/friend/sendFriendRequestWithChild",
  getAllKeywords: "/master/getAllUniqueKeywords",
  getAllResourcesData: "/save/resource/getAllResource",
  getAllResourcesServeData: "/serve/resource/getAllResource",
  getAllResourcegroup: "/group/groupResources",
  groupDetail: "/group/getGroupDetails",
  getSubscriptionPlanList: "/user/subscriptionPlanList",
  createServeResource: "/serve/resource/addServe",
  updateServeResource: "/serve/resource/updateServe",
  userfindemail: "/user/userFindEmail",
  freeBuySubscription: "/user/payment/subscriptionNow",
  subscriptionConfirm: "/user/payment/subscriptionConfirm",
  paymentConfirm: "/user/subscriptionComfirm",
  purchaseResourceConfirm: "/user/resource/resourceConfirm",
  purchaseResourcePayNow: "/user/resource/resourcePurchasePayNow",
  notificationlist: "/notification/getNotificationTop",
  notificationCount: "/notification/notificationCount",
  notificationlistall: "/notification/getAllNotification",
  markallnoticeread: "/notification/markAllNotificationRead",
  getUserSubscription: "/user/getUserSubscription",
  stripeAccountActivation: "/user/stripeAccount",
  userResourcePurchaseHistory: "/user/resource/getAllUserResourcePurchase",
  groupUserResourcePurchase: "/user/resource/getAllGroupUserResourcePurchase",
  uploadContentServe: "/serve/resource/fileUploadServe",
  uploadContentSave: "/save/resource/fileUploadSave",
  getSharedResources: "/share/resource/getSharedResources",
  freeSubscription: "/user/freeSubscription",
  getSystemSetting: "/getSystemSetting",
  subscriptionConfirmUpgrade: "/user/payment/subscriptionConfirm/upgrade",
  subscriptionUpgrade: "/user/payment/subscription/upgrade",
  shareResource: "/share/resource/shareResource",
  changeResourceViewableFriend:
    "/user/resource/saved/changeResourceViewableFriend",
  sharedResourcelike: "/share/resource/like",
  createResourceComment: "/share/resource/createResourceComment",
  getSharedResourceComment: "/share/resource/getSharedResourceComment",
  token: "/user/token",
  removeResource: "/user/resource/saved/delete",
  getallresourceshomepage: "/save/resource/showOnHomePage",
  createNewGroup: "/group/addGroup",
  announcementStatus: "/share/announcementStatus",
  groupImage: "/group/imageGroupUpload",
  getMyGroup: "/group/getMyGroup",
  getMyGroupSidebar: "/group/getMyGroupSidebar",
  updateMyGroup: "/group/updateGroup",
  getIdByGroup: "/group/getGroupById",
  groupImageDelete: "/group/imageGroupDelete",
  getSharedChat: "/group/getSharedChat",
  getSidebarGroupImage: "/group/getSideBarGroupList",
  getGroupMember: "/group/getGroupMember",
  getMemberRequest: "/group/getMemberRequest",
  shareInvitationofGroupFeed: "/group/share/shareInvitationGroup",
  getAllSaleResourcesHistory: "/user/resource/getAllSaleResourcePurchase",
  getUserProfile: "/user/resource/saved/getParentSavedResources",
  subscriptionCanceled: "/user/subscriptionCanceled",
  requestToShareChildProfile: "/child/requestToShareChildProfile",
  createOngoingSchedule: "/mentorSession/createOngoingSchdeule",
  getAllContentType: "/master/getAllContentType?status=enable",
  getChildList: "/child/getMyChildList",
  getFriendLinkUrl:"/user/friendInviteLink",
  getMyChildListSide: "/child/getMyChildListSide",
  getUserSubscriptionPlan: "/user/userSubscriptionPlan",
  getUserSearch: "/search/userSearch",
  getFileCheckSaveResource: "/save/resource/fileCheck",
  getFileCheckServeResource: "/serve/resource/fileCheck",
  getsearchrecord: "/search/shareResource",
  timeZone: "/master/getTimeZone",
  getAllnotification: "/notification/getAllSettingNotification",
  markSingleNotification: "/notification/markSingleNotificationRead",
  notificationStatus: "/notification/notificationUpdate",
  updateNotification: "/notification/updateSettingNotification",
  chatGptHistory: "/search/chatGpthistory",
  getChatGptHistory: "/search/getchatGpthistory",
  friendList: "/share/friend/myFriendlist",
  allFriend: "/user/userList",
  groupResourcesPurchase: "/group/getGroupResourcesPurchase",
  JoinGroup: "/group/joinGroup",
  getGroupList: "/group/getListGroup",
  removeMember: "/group/removeGroupMember",
  getShareResourceByGroup: "/group/group/getSharedResources",
  updateJoinStatus: "/group/updateGroupJoinStatus",
  joinGroupList: "/group/getJoinGroupList",
  invitationGroup: "/group/invitationGroup",
  shareListGroup: "/group/shareListGroup",
  shareListResource: "/save/shareListResource",
  getAllMasterImage: "/master/getAllMasterImage",
  deleteCommets: "/share/resource/deleteResourceComment",
  createReview: "/group/createReview",
  totalViewResource: "/save/resource/totalView",
  getReview: "/group/getReview",
  subGroupList: "/group/getAllGroupToGroup",
  removeGroupSubGroup: "/group/removeGroupToMain",
  groupCodeUpadte: "/user/groupCodeUpdate",
  groupSuggestion: "/group/groupRecommendations",
  mentorSessionResource: "/mentorSession/resource/get",
  reoccurringSchedule: "/mentorSession/createReoccurringSchedule",
  dateWiseSchedule: "/mentorSession/createDateWiseSchdeule",
  getScheduleDatwWise: "/mentorSession/getScheduleDateWise",
  getScheduleType: "/mentorSession/getScheduleType",
  removeSchdule: "/mentorSession/changeMSScheduleStatus",
  getMentoringTutor: "/mentorSession/getMentoringTutor",
  getMSMasterData: "/mentorSession/getMSMasterData",
  getSchedualManualDate: "/mentorSession/getScheduleManualDate",
  getSchedualAutoReoccurring: "/mentorSession/getScheduleAutoReoccurring",
  getScheduleAutoReoccurringTimes: "/mentorSession/getScheduleAutoReoccurringTimes",
  saveMS: "/mentorSession/saveMS",
  saveMSSingUp: "/mentorSession/createMentoringSignUp",
  userPurchaseCard: "/mentorSession/saveMS",
  getStripe: "/user/getStripe",
  updateFcmToken: "/user/updateFCMId",
  sendPushNotifications: "/user/sendnotification",
  getMSDataUser: "/mentorSession/getMSDataBuyer",
  buyerResource: "/mentorSession/resource/getMSDataBuyer",
  vendorResource: "/mentorSession/resource/getMSDataVendor",
  cancelMSDateTime: "/mentorSession/cancelMSDateTime",
  rescheduleMSDateTime: "/mentorSession/rescheduleMSDateTime",
  getMSDataVendor: "/mentorSession/getMSDataVendor",
  getResourceMentoring: "/mentorSession/getResourceMentoring",
  getMentoringPurchase: "/mentorSession/getMentoringPurchase",
  paymentUrl: "/mentorSession/paymentUrl",
  getMyScheduleDateWise: "/mentorSession/getMyScheduleDateWise",
  getMyScheduleDates: "/mentorSession/getMyScheduleDates",
  getViewNotification: "/notification/getViewNotification",
  viewNotification: "/notification/viewNotification",
  addEvent: "/save/resource/addEvent",
  getStripeAccount: "/user/stripe",
  userAccountAdd: "/user/stripeCreateAccount",
  groupAccountAdd: "/group/stripeAccount",
  getTickets: "/zendDesk/getTicketList",
  getZenDeskToken: "/zendDesk/getZenddeskToken",
  getTicketDetail: "/zendDesk/getTicketDetail",
  getTicketComment: "/zendDesk/getTicketComments",
  addTicketComment: "/zendDesk/addComment",
  addTicketAttachments: "/zendDesk/uploadAttachment",
  addTicket: "/zendDesk/createTicket",
  deleteTicket: "/zendDesk/deleteTicket",
  deleteAttachment: "/zendDesk/deleteAttachment",
  showAttachment: "/zendDesk/showAttachment",
  checkToken: "/user/checkToken",
  getMoreInfo: "/save/resource/addMoreInfo",
  Enroll: "/save/resource/addEnroll",
  StripeAccountLink: "/user/stripeAccount",
  GetHomeVideo: "/user/getHomePageVideo",
  searchAlgolia: "/search/algolia",
  chatGroupDelete: "/chatMultiple/deleteGroup",
  CreateChatGptCJ: "/search/resource/CreateChatGptCJ",
  CreateChatGptAmazon: "/search/resource/CreateChatGptAmazon",
  CreateChatGptnonCJ: "/search/resource/CreateChatGptnonCJ",
  NotificationCount: "/notification/notificationCount",
  NotificationCountUpdate: "/notification/notificationCountUpdate",
  GetMyGroupList: "/group/getMyGroupList",
  searchChatgpt: "/search/resource/CreateChatGptResource",
  UpdateAccpedReject: "/group/updateAccpedReject",
  DeleteUserGroup: "/chatMultiple/deleteUserGroup",
  getPendingAdminUser: "/group/getMemberPendingAdmin",
  removeAdmin: "/group/removeMemberPendingAdmin",
  shareInvitationGroup: "/group/shareInvitationGroup",
  getAllGroupList: "/group/getNewAllGroupList"
};

