import React, { useEffect } from "react";
import "./friendList.css";
import { decodeToken, style, toastifyMessage } from "../../utilities/CustomFunctions";
import { useState, useRef } from "react";
import InviteFriendpopup from "../Common.WebAppMate/InviteFriendpopup";
import { makePostRequestForAll ,makePostRequest} from "../../services/api";
import { URLS } from "../../constants";
import moment from "moment";
import Threedotspopup from "../Common.WebAppMate/Threedotspopup";
import PermissionSharepopup from "./Permissonsharepopup";
import { getFriendList } from "../../store/reducers/userReducer";
import { Link, useNavigate } from "react-router-dom";
import PermissionRemovepopup from "./RemovePermisson";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Removepopup from "./RemovefriendPopup";
import { useDispatch, useSelector } from "react-redux";
import FriendsImg from "../../assets/Images/friendes.jpg";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SingleChat from "../Chat.WebAppMate/SingleChat";
import Head from "../Layout.WebAppMate/head/Head";
import ResetResource from "../../assets/Images/reset-resource.png";
import ResetResourceWhite from "../../assets/Images/reset-resource-white.png";
import { getOpenGlobalChat } from "../../store/reducers/userReducer";
import { useLocation } from "react-router-dom";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
const URL = process.env.REACT_APP_URL;
/**
 * create components is used for friend list
 * @author krishna dobariya <krishna.webappmate@gmail.com>
 */

function PurchasePages() {

  const pathofFriend = JSON.parse(sessionStorage.getItem("locationfriendRoute"));
 const extractPathName = pathofFriend?.pathname;
 const friendID = extractPathName?.split('/').pop();


const GetFriendLink = () => {
  let data = {
    friend_id: friendID,
  };
  makePostRequest(URLS.getFriendLinkUrl, null, data, null)
     .then((res) => {
         if (res.code === 201) {
          //window.location.href = "/purchase-pages-friend";
         }
     })
     .catch((error) => {
        console.log(error);
     });
};

useEffect(()=>{
  GetFriendLink();
},[friendID])

// remove ids 
useEffect(()=>{
  sessionStorage.removeItem("sendGroupInvite");
  sessionStorage.removeItem("sendInviteLink");
},[])

  const location = useLocation();
  let user_id = decodeToken().userAuth.id;
  const [invitefriend, setInvitefriend] = useState(false);
  const [peddingRequest, setPeddingRequest] = useState([]);
  const [myFriend, setMyFriend] = useState([]);
  const [copied, setCopied] = useState(false);
  const [id, setId] = useState("")
  const [grp, setGrp] = useState(0);
  const [matchId, setMatchId] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [sharpermision, setSharepermision] = useState(false);
  const [removePermissions, setRemovePermissions] = useState(false);
  const [show, setshow] = useState(false);
  const [spin, setSpin] = useState(false);
  const [page, setPage] = useState(1);
  const [status, setStatus] = useState(true);
  const [panddingStatus, setPanddingStatus] = useState(true)
  const [requestPage, setRequestPage] = useState(1);
  const [searchPage, setSearchPage] = useState(1);
  const [searchStatus, setSearchStatus] = useState(false);
  const [mySearchFriend, setMySearchFriend] = useState([]);
  const navigate = useNavigate()
  const [showPopup, setShowPopup] = useState(false)
  const menuRef = useRef(null);
  const menuBtnRef = useRef(null);
  const menuBtnsRef = useRef(null);
  const [viewStatus, setViewStatus] = useState(true)
  let dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [friendId, setFriendId] = useState("");
  const usersrote = useSelector((store) => store?.user);
  const [cbstatus, setCbstatus] = useState("");
  const [panddingInner, setPanddingInner] = useState({
    friend: false,
    pandding: false,
    search: false
  })
  const handleOpen = (resourceid, frnd_id) => {
    setOpen(true);
    setFriendId(frnd_id);
  };

  const permision = () => {
    setSharepermision(true);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  const removePermission = () => {
    setRemovePermissions(true);
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }
  useEffect(() => {
    setPeddingRequest(usersrote?.panddingList)
  }, [usersrote?.panddingList])

  // this function is use for invite friends
  const InviteFriend = () => {
    const data = {
      "friend_id": friendId
    }
    makePostRequestForAll(URLS.friendInvite, data)
      .then((res) => {
        if (res.code === 201) {
          toastifyMessage(res.message, "success");
          setOpen(false);
        } else {
          toastifyMessage(res.message, "warn");
          setOpen(false);
        }
      })
  }

  //  this function is use for get my friend
  const getFriends = () => {
    page === 1 && setSpin(true);
    const data = {
      "user_id": user_id,
      "searchKey": "",
      "page_number": page
    }
    makePostRequestForAll(URLS.myFriendList, data)
      .then((res) => {
        //Here we are adding a key for remove message count
        const students = res.data.friendList;
        const newdata = students.map((item) => ({
          ...item,
          Countlabel: true
        }))
        if (page === 1) {
          setMyFriend(newdata)
        } else {
          setMyFriend([...myFriend, ...newdata])
        }
        if (res.data.remaining_count === 0) {
          setStatus(false)
        } else {
          setStatus(true)
        }
        setSpin(false)
        setPanddingInner({ ...panddingInner, "friend": false })
      })
      .catch((error) => {
        setSpin(false)
        setPanddingInner({ ...panddingInner, "friend": false })
        console.log("error", error);
      });
  }
  //This function used for remove count onclick of chat button
  const markCountRead = (id, status) => {
    for (let i = 0; i < myFriend.length; i++) {
      if (myFriend[i].id === id) {
        myFriend[i].Countlabel = status;
      }
    }
  }
  // get panding request list
  const getPandingRequest = () => {
    var LIst = [];
    var LIst1 = [];
    requestPage === 1 && setSpin(true)
    const data = {
      "searchKey": "",
      "page_number": requestPage,
      "item_per_page": 4
    }
    makePostRequestForAll(URLS.ListOfPendingFriendRequest, data)
      .then((res) => {
        LIst1 = [...res?.data?.friendRequest]
        const data1 = {
          "page_number": requestPage,
          "item_per_page": 4
        }
        makePostRequestForAll(URLS.myFriendEmailPendingList, data1)
          .then((response) => {
            LIst = [...response?.data?.friendRequest]
            const a = [...LIst1, ...LIst]
            if (requestPage === 1) {
              setPeddingRequest(a);
            } else {
              setPeddingRequest([...peddingRequest, ...a]);
            }
            if (response.data.remaining_count === 0 && res.data.remaining_count === 0) {
              setPanddingStatus(false)
            } else {
              setPanddingStatus(true)
            }
            setSpin(false)
            setPanddingInner({ ...panddingInner, "pandding": false })
          })
      })
      .catch((error) => {
        setSpin(false)
        setPanddingInner({ ...panddingInner, "pandding": false })
        console.log("error", error);
      });
  }

  // this function is use for get search
  const getSearch = () => {
    searchPage === 1 && setSpin(true)
    const data = {
      "searchKey": searchItem,
      "page_number": searchPage,
    }
    makePostRequestForAll(URLS.friendSearch, data)
      .then((res) => {
        if (res?.data?.friendList?.length > 0) {
          setMyFriend([]);
          setPeddingRequest([]);
        }
        if (searchPage === 1) {
          setMySearchFriend(res?.data?.friendList)
          setViewStatus(false)
        } else {
          setMySearchFriend([...mySearchFriend, ...res?.data?.friendList]);
          setViewStatus(false)
        }
        if (res?.data?.remaining_count === 1) {
          setSearchStatus(true)
        } else {
          setSearchStatus(false)
        }
        setSpin(false)
        setPanddingInner({ ...panddingInner, "search": false })
      })
      .catch((error) => {
        setSpin(false)
        setPanddingInner({ ...panddingInner, "search": false })
        console.log("error", error);
      });
  }
  // this fuction is use for chnage status staus of invitation--------
  const acceptInvitation = (id, status) => {
    setSpin(true)
    const payload = {
      id: id,
      status: status,
      last_updated: moment().format(),
    };
    makePostRequestForAll(URLS.acceptOrRejectRequestFriend, payload)
      .then((res) => {
        toastifyMessage(res.message, "success")
        makePostRequestForAll(URLS.newFriendRequest, { user_id: user_id })
          .then((res) => {
            dispatch(getFriendList(res.data))
          })
          .catch((error) => {
            console.log("error", error);
          });

        setSpin(false)
        if (status === "unfriend") {
          setShowPopup(false)
        }
      })
      .catch((error) => {
        setSpin(false)
        console.log("error", error);
      });
  };
  // start this fuction is use for Api calling on state chnage ------
  useEffect(() => {
    getFriends();
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    getPandingRequest();
    // eslint-disable-next-line
  }, [requestPage]);

  useEffect(() => {
    searchItem && getSearch();
    // eslint-disable-next-line
  }, [searchPage]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchItem === "") {
      toastifyMessage("Please enter text", "error")
    } else {
      if (searchPage === 1) {
        getSearch()
      } else {
        setSearchPage(1)
      }
    }
  }

  const handleCopyClick = (textToCopy) => {
    // const id = btoa(CryptoJS.AES.encrypt(textToCopy.toString(), secretKey).toString())
    navigator.clipboard.writeText(`${URL}/user-profile/${textToCopy}`)
      .then(() => {
        setCopied(true);
        console.log("copied")
      })
      .catch(error => {
        console.error('Error copying text:', error);
      });
  };

  // end Api calling on state chnage ----------
  const handleClickOutside = (e) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      !menuBtnRef.current.contains(e.target)
    ) {
      setshow(false);

    }
  };
  const handleClickOutsides = (e) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(e.target) &&
      !menuBtnsRef.current.contains(e.target)
    ) {
      setInvitefriend(false);
    }
  };
  // this function is use for any event on load
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsides, true);
    document.addEventListener("mousedown", handleClickOutside, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true);
      document.removeEventListener("mousedown", handleClickOutsides, true);
    };
  }, []);
  useEffect(() => {
    document.body.classList.add("purchasepagefriend");
    return () => {
      document.body.classList.remove("purchasepagefriend");
    };
  }, []);
  // end

  // this function use for chat
  const AddUser = (id, userData) => {
    dispatch(getOpenGlobalChat({ ...usersrote?.globalOpenChat, chatOpen: false }))
    makePostRequestForAll(URLS.addChatUser, { chat_with: id })
      .then((res) => {
        console.log("addChatUser", res)
        if (res.code === 200) {
          dispatch(getOpenGlobalChat({
            ...usersrote?.globalOpenChat, chatOpen: true, block_by_other_person: res?.data?.chat_by_block,
            chat_with_block: res?.data?.chat_with_block,
            chat_by_block: res?.data?.block, userMapId: res?.data?.id, userDetail: userData, senderId: id, group: grp, pageName: "friend"
          }))
        }
      }
      )
      .catch((e) => {
        console.log("error:::::", e)
      });
  };
  // useEffect(() => {
  //   // This effect runs whenever location (URL) changes so chat window not open again when you come back on this page again
  //   dispatch(getOpenGlobalChat(
  //     {chatOpen : false, userDetail : "", userMapId: "", senderId: "", group: "", pageName : "" })) // Reset or clear the userstore data here
  // }, [location.pathname]);
  useEffect(() => {
    if (cbstatus?.callback === "callback") {
      dispatch(getOpenGlobalChat({ ...usersrote?.globalOpenChat, chatOpen: true, userMapId: cbstatus?.id?.id, userDetail: cbstatus?.data, group: cbstatus?.callback === "callback" ? 0 : 1, pageName: "friend" }))
    }
  }, [cbstatus]);

  return (
    <>
      <Head title="Friends"
        content="Connect with friends old and new, working together for all students on our platform's social network."
        name="friends" ></Head>
      {
        spin ? <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div> :
          <div className="col-lg-10">
            <div className="paddingcontrighs">
              <div className="purchasebartop">
                <div className="row">
                  <div className="col-lg-8 col-8 mobbilecslines">
                    <div className="row">
                      <div className="purchasebarsearch">
                        <div className="searchbarlist">
                          <form onSubmit={handleSearch}>
                            <input
                              type="text"
                              className="name"
                              name="searchItem"
                              placeholder="Search for MatchED Members"
                              onChange={(e) => setSearchItem(e.target.value)}
                              value={searchItem}
                            />
                            <button type="submit">
                              <i className="fa fa-search"></i>
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-4 my-2 mobbilecslines">
                    <div className="d-flex gap-2">
                      <div className="purchasebarbuttons ">
                        <button className="btn btn-md float-end addresource mx-1" onClick={() => {
                          setRequestPage(1); setViewStatus(true); setSearchItem(""); setMySearchFriend([]); getFriends(); getPandingRequest(); setRequestPage(1)
                        }}>
                          <img src={ResetResource} alt="Reset Resource images" width="20" className="resourcehoverImg" />
                          <img src={ResetResourceWhite} alt="Reset Resource images" width="20" className="resourceNormalImg" />
                        </button>
                      </div>
                      <div className="purchasebarbuttons">
                        <Link
                          to=""
                          ref={menuBtnsRef}
                          onClick={() => setInvitefriend(true)}
                          className="vieworder-button"
                        >
                          Invite a Friend to MatchED
                        </Link>
                        {invitefriend === true ? (
                          <InviteFriendpopup datar={() => setInvitefriend(!invitefriend)} forwardedref={menuRef} funcall={() => { setRequestPage(1); setPeddingRequest([]); getPandingRequest() }} />
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {viewStatus === false ? <>
                <div className="bookmoniterdiv bookmoniterdiv5">
                  <div className="row">
                    <div className="col-lg-9 col-9">
                      <h3>Searched people</h3>
                    </div>
                    <div className="col-lg-3 col-3">
                      <div className="sellallbutton">
                      </div>
                    </div>
                  </div>
                </div>
                <div className="teampurchasepages">
                  <div className="row">
                    <div className="teamboxinnercontent">
                      <div className="row">
                        {mySearchFriend.length > 0 ?
                          mySearchFriend.map((val, index) => {
                            return (
                              <>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-12 teamcolumnmob" >
                                  <div className="teamboxinnerpart">
                                    <div className="imgcircle">
                                      <img className="img-fluid" src={val.photo} onClick={() => navigate(`/user-profile/${val?.friend}`)} alt="matched noy found" />
                                    </div>
                                    {
                                      val?.accepted && <div
                                        className="dots"
                                        ref={menuBtnRef}
                                        onClick={() => {
                                          setshow(!show);
                                          setMatchId(index);
                                        }}
                                      >
                                        <i className="fa fa-ellipsis-v"></i>
                                        {show === true && matchId === index ? (
                                          <Threedotspopup val={permision} id={val?.friend} remove={removePermission} forwardedref={menuRef} />
                                        ) : null}
                                        {sharpermision === true && matchId === index ? (
                                          <div className="friendRequestpermission">
                                            <PermissionSharepopup
                                              data={() => setSharepermision(false)}
                                              userId={val.friend}
                                              userName={val.name}
                                            />
                                          </div>
                                        ) : null}
                                        {removePermissions && matchId === index ?
                                          <PermissionRemovepopup
                                            data={() => setRemovePermissions(false)}
                                            userId={val.friend}
                                            userName={val.name}

                                          /> : ""
                                        }
                                      </div>
                                    }
                                    <h2 className="cursor-pointer  text-capitalize" onClick={() => navigate(`/user-profile/${val?.friend}`)}> {val.name} </h2>
                                    {/* <div>
                                      <div className="content-text-bsvg">
                                        <p className="my-1" onClick={() => handleCopyClick(val?.friend)}>Copy link to share</p>
                                        <div className="copy-icon" onClick={() => handleCopyClick(val?.friend)}>
                                          <div className="tooltip">{copied ? "copied" : "Copy Text"}</div>
                                          <ContentCopyIcon className="mt-2" />

                                        </div>
                                      </div>
                                    </div> */}
                                     
                                    <div>
                                    
                                      <p onClick={() => navigate(`/user-profile/${val?.friend}`)}>
                                        {
                                          val?.mutualFriendImage[0]?.photo &&
                                          <>
                                            <span>
                                              <img
                                                className="img-fluid short1"
                                                src={val?.mutualFriendImage[0]?.photo}
                                                alt="mutual Friend"
                                              />
                                              {
                                                val?.mutualFriendImage[1]?.photo && <img
                                                  className="img-fluid short2"
                                                  src={val?.mutualFriendImage[1]?.photo}
                                                  alt="mutual Friend"
                                                />
                                              }
                                            </span>
                                          </>
                                        }
                                        {val.mutualFriend} Mutual Friends
                                      </p>
                                    </div>
                                    <div className="accpectbuttoncover">
                                      <div className="accpectbuttoncover">
                                        {(val.accepted === false && val.pending === false && val.event === false) ?
                                          <button className="accpectbutton my-4"
                                            onClick={() => {
                                              handleOpen(val.id, val.friend);
                                            }}>Invite</button> : (val.pending === true && val.event === true) ?
                                            (
                                              <>
                                                <button
                                                  className="accpectbutton"
                                                  onClick={() =>
                                                    acceptInvitation(val.id, "accepted")
                                                  }
                                                >
                                                  Accept
                                                </button>
                                                <button
                                                  className="declinebutton"
                                                  onClick={() =>
                                                    acceptInvitation(val.id, "rejected")
                                                  }
                                                >
                                                  Decline
                                                </button>
                                              </>
                                            )
                                            : (val.pending === true && val.event === false) ?
                                              <button
                                                className="declinebutton friendaddbt my-4"
                                              >
                                                Pending
                                              </button> : (val.accepted === true) ? <button
                                                className="accpectbutton my-4"
                                                onClick={() => { setShowPopup(true); setId(val.id); setMatchId(index) }}
                                              >
                                                Remove Friend
                                              </button> : "null"}
                                      </div>
                                      {(showPopup === true && matchId === index) ? (
                                        <Removepopup
                                          open={showPopup}
                                          handleClose={() => setShowPopup(false)}
                                          id={id}
                                          handlefun={() => { setViewStatus(true); setSearchItem(""); setMySearchFriend([]); getFriends(); getPandingRequest() }}
                                        />
                                      ) : ""}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }) : <div className="nodataFoundinfo">
                            <strong className="text-center">No data found</strong>
                            <img src={FriendsImg} className="save img-fluid" style={{ width: "100%" }} alt="matched not found" />
                          </div>
                        }
                      </div>
                      <div className="show-more">
                        {searchStatus && <p className="text-center" onClick={() => { setPanddingInner({ ...panddingInner, "search": true }); setSearchPage(searchPage + 1) }}>Show more</p>}
                        {
                          panddingInner?.search && <div className="share-spiner"><FourDotsLoader /></div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </> : ""}
              {
                viewStatus && <>
                  <div className="bookmoniterdiv">
                    <div className="row">
                      <div className="col-lg-9 col-9">
                        <h3>Your Friends </h3>
                      </div>
                      <div className="col-lg-3 col-3">
                        <div className="sellallbutton">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="teampurchasepages">
                    <div className="row">
                      <div className="teamboxinnercontent">
                        <div className="row">
                          {myFriend.length > 0 ? myFriend.map((val, index) => {
                            return (
                              <>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-12 teamcolumnmob" >
                                  <div className="teamboxinnerpart">
                                    <div className="imgcircle" onClick={() => navigate(`/user-profile/${val?.userId}`)}>
                                      <img className="img-fluid" src={val.photo} onClick={() => navigate(`/user-profile/${val?.userId}`)} alt="matched" />
                                    </div>
                                    <div
                                      className="dots"
                                      ref={menuBtnRef}
                                      onClick={() => {
                                        setshow(!show);
                                        setMatchId(index);
                                      }}
                                    >
                                      <i className="fa fa-ellipsis-v"></i>
                                      {show === true && matchId === index ? (
                                        <Threedotspopup val={permision} id={val?.userId} remove={removePermission} forwardedref={menuRef} />
                                      ) : null}
                                      {sharpermision === true && matchId === index ? (
                                        <div className="friendRequestpermission">
                                          <PermissionSharepopup
                                            data={() => setSharepermision(false)}
                                            userId={val.userId}
                                            userName={val.name}
                                          />
                                        </div>
                                      ) : null}
                                      {removePermissions && matchId === index ?
                                        <PermissionRemovepopup
                                          data={() => setRemovePermissions(false)}
                                          userId={val.userId}
                                          userName={val.name}
                                        /> : ""
                                      }
                                    </div>
                                    <h2 className="cursor-pointer  text-capitalize" onClick={() => navigate(`/user-profile/${val?.userId}`)}> {val.name} </h2>
                                    {/* <div>
                                      <div className="content-text-bsvg">
                                        <p className="my-1" onClick={() => handleCopyClick(val?.userId)}>Copy link to share</p>
                                        <div className="copy-icon" onClick={() => handleCopyClick(val?.userId)}>
                                          <div className="tooltip">{copied ? "copied" : "Copy Text"}</div>
                                          <ContentCopyIcon className="mt-2" />

                                        </div>
                                      </div>
                                    </div> */}
                                    <div>
                                  
                                      <p className="cursor-pointer" onClick={() => navigate(`/user-profile/${val?.userId}`)}>
                                        {
                                          val?.mutualFriendImage[0]?.photo && <>
                                            <span>
                                              <img
                                                className="img-fluid short1"
                                                src={val?.mutualFriendImage[0]?.photo}
                                                alt="mutual Friend"
                                              />
                                              {
                                                val?.mutualFriendImage[1]?.photo && <img
                                                  className="img-fluid short2"
                                                  src={val?.mutualFriendImage[1]?.photo}
                                                  alt="mutual Friend"
                                                />
                                              }
                                            </span>
                                          </>
                                        }
                                        {val.mutualFriend} Mutual Friends
                                      </p>
                                    </div>
                                    <div className="accpectbuttoncover">
                                      {
                                        val?.msgcount > 0 && val.Countlabel === true
                                          ? <div className="chat-count">
                                            <span >{val?.msgcount}</span>
                                          </div> : ""
                                      }
                                      <button
                                        className="accpectbutton"
                                        onClick={() => {
                                          AddUser(val?.userId, {
                                            "name": val?.name, "image": val?.photo,
                                            callback: cbstatus?.callback === "callback" ? "ss" : "friend"
                                          })
                                          markCountRead(val.id, false)
                                        }}
                                      >
                                        Chat
                                      </button>
                                      <button
                                        className="declinebutton"
                                        onClick={() => { setShowPopup(true); setId(val.id); setMatchId(index) }}
                                      >
                                        Remove Friend
                                      </button>
                                      {(showPopup === true && matchId === index) ? (
                                        <Removepopup
                                          open={showPopup}
                                          handleClose={() => setShowPopup(false)}
                                          id={id}
                                          handlefun={() => { setViewStatus(true); setSearchItem(""); setMySearchFriend([]); getFriends(); getPandingRequest() }}
                                        />
                                      ) : ""}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }) : <div className="nodataFoundinfo">
                            <strong className="text-center">Connect with friends old and new.</strong>
                            <img src={FriendsImg} className="save img-fluid" style={{ width: "100%" }} alt="matched not found" />
                          </div>
                          }
                        </div>
                        <div className="show-more">
                          {status && <p className="text-center" onClick={() => { setPanddingInner({ ...panddingInner, "friend": true }); setPage(page + 1) }}>Show more</p>}
                          {
                            panddingInner?.friend && <div className="share-spiner"><FourDotsLoader /></div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {
                viewStatus && <>
                  <div className="bookmoniterdiv mt-0">
                    <div className="row">
                      <div className="col-lg-9 col-9">
                        <h3>Friend Requests </h3>
                      </div>
                      <div className="col-lg-3 col-3">
                        <div className="sellallbutton">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="teampurchasepages">
                    <div className="teamboxinnercontent">
                      <div className="row">
                        {peddingRequest.length > 0 ?
                          peddingRequest.map((val, index) => {
                            return (
                              <>
                                <div className="col-lg-3 col-md-3 col-sm-3 col-12 teamcolumnmob" >
                                  <div className="teamboxinnerpart">
                                    <div className="imgcircle" onClick={() => { val.event && navigate(`/user-profile/${val?.userId}`) }}>
                                      <img className="img-fluid" src={val.photo} alt="matched user" />
                                    </div>
                                    <h2 onClick={() => { val.event && navigate(`/user-profile/${val?.userId}`) }} className="cursor-pointer text-capitalize">{val.name}</h2>
                                    <div>
                                      {/* <div className="content-text-bsvg">
                                      <p className="my-1" onClick={() => handleCopyClick(val?.userId)}>Copy link to share</p>
                                      <div className="copy-icon" onClick={() => handleCopyClick(val?.userId)}>
                                        <div className="tooltip">{copied ? "copied" : "Copy Text"}</div>
                                        <ContentCopyIcon className="mt-2" />

                                      </div>
                                    </div> */}
                                 
                                      <div>
                                  
                                        <p onClick={() => { val.event && navigate(`/user-profile/${val?.userId}`) }}>
                                          {
                                            val?.mutualFriendImage[0]?.photo && <>
                                              <span>
                                                <img
                                                  className="img-fluid short1"
                                                  src={val?.mutualFriendImage[0]?.photo}
                                                  alt="mutual Friend"
                                                />
                                                {
                                                  val?.mutualFriendImage[1]?.photo && <img
                                                    className="img-fluid short2"
                                                    src={val?.mutualFriendImage[1]?.photo}
                                                    alt="mutual Friend"
                                                  />
                                                }
                                              </span>
                                            </>
                                          }
                                          {val.mutualFriend} Mutual Friends
                                        </p>
                                      </div>
                                      <div className="accpectbuttoncover">
                                        {val.request_by !== user_id ? (
                                          <>
                                            <button
                                              className="accpectbutton"
                                              onClick={() =>
                                                acceptInvitation(val.id, "accepted")
                                              }
                                            >
                                              Accept
                                            </button>
                                            <button
                                              className="declinebutton"
                                              onClick={() =>
                                                acceptInvitation(val.id, "rejected")
                                              }
                                            >
                                              Decline
                                            </button>
                                          </>
                                        ) : <>
                                          <button
                                            className="declinebutton friendaddbt my-4"
                                          >
                                            Pending
                                          </button>
                                        </>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          }) : <div className="nodataFoundinfo">
                            <strong className="text-center">Connect with friends old and new.</strong>
                            <img src={FriendsImg} className="save img-fluid" style={{ width: "100%" }} alt="matched " />
                          </div>
                        }
                      </div>
                      <div className="show-more my-3">
                        {panddingStatus && <p className="text-center" onClick={() => { setPanddingInner({ ...panddingInner, "pandding": true }); setRequestPage(requestPage + 1) }}>Show more</p>}
                        {
                          panddingInner?.pandding && <div className="share-spiner"><FourDotsLoader /></div>
                        }
                      </div>
                    </div>
                  </div>
                  {
                    usersrote?.globalOpenChat?.chatOpen && usersrote?.globalOpenChat?.pageName === "friend" &&
                    <SingleChat groupName={usersrote?.globalOpenChat?.userDetail} chatClose={() => dispatch(getOpenGlobalChat(
                      { chatOpen: false, userDetail: "", userMapId: "", senderId: "", group: "", pageName: "" }))} userMapId={usersrote?.globalOpenChat?.userMapId} senderId={usersrote?.globalOpenChat?.senderId} group={usersrote?.globalOpenChat?.group} getAllUser={() => { }}
                      statuspage={"friend"}
                      callback={(data) => { setCbstatus(data) }}
                      funCall={getFriends} />
                  }
                </>
              }
            </div>
            <Modal
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                  className="text-center"
                  style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
                >
                  Are you sure you want to invite this friend?
                </Typography>
                <Typography
                  id="modal-modal-description"
                  sx={{ mt: 3 }}
                  className="d-flex justify-content-center gap-4"
                >
                  <button className="child-delete" onClick={InviteFriend} >
                    Yes
                  </button>{" "}
                  <button className="child-cancel" onClick={() => setOpen(false)}>
                    Cancel
                  </button>
                </Typography>
              </Box>
            </Modal>
          </div>
      }

    </>
  );
}
export default PurchasePages;
