import { useEffect, useRef, useState } from "react";
import React from "react";
import "./parentragistrationform.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  imageUrl,
  makeFormDateRequset,
  makeGetRequest,
} from "../../services/api";
import { URLS } from "../../constants";
import "react-toastify/dist/ReactToastify.css";
import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import jwt_decode from "jwt-decode";
import { PlusOutlined } from "@ant-design/icons";
import { makePostRequest } from "../../services/api";
import { capitalizeWords, toastifyMessage, Emailvalidation, decodeToken } from "../../utilities/CustomFunctions";
import { useDispatch } from "react-redux";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { getchildList, setUserData } from "../../store/reducers/userReducer";
import Select from "react-select";
import StripePopup from "../Common.WebAppMate/StripePopup";
import Head from "../Layout.WebAppMate/head/Head";
import { options } from "../../services/common.services";
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


// /*
//      * Parentregistration  function is used to register user
//      * @author Naresh Kumar <naresh.gulati@webappmate.com>
//      * created on: 15-4-2023
//      * /

const Parentrgistrationform = () => {

const getFriendId = sessionStorage.getItem("friendID")
const selectedPlan = sessionStorage.getItem("selectedPlan")
const getInviteLinkId = sessionStorage.getItem("sendInviteLink");
const getsendGroupId = sessionStorage.getItem("sendGroupInvite");
const [counryName,setCountryNamme]=useState("")
const [countryCode, setCountryCode] = useState('us');

  var childdisplay = [
    "first",
    "second",
    "third",
    "fourth",
    "fifth",
    "sixth",
    "seventh",
    "eighth",
    "ninth",
    "tenth",
    "eleventh",
    "twelveth",
    "thirteenth",
    "fourteenth",
    "fifteenth",
    "sixteenth",
    "seventeenth",
    "eighteenth",
    "nineteenth",
    "twentieth",
    "twenty first",
    "twenty second",
    "twenty third",
    "twenty fourth",
    "twenty fifth",
    "twenty sixth",
    "twenty seventh",
    "twenty eighth",
    "twenty ninth",
    "thirtieth",
    "thirty first",
    "thirty second",
    "thirty third",
    "thirty fourth",
    "thirty fifth",
    "thirty sixth",
    "thirty seventh",
    "thirty eighth",
    "thirty ninth",
    "fourtieth",
    "fourty first",
    "fourty second",
    "fourty third",
    "fourty fourth",
    "fourty fifth",
    "fourty sixth",
    "fourty seventh",
    "fourty eighth",
    "fourty ninth",
    "fiftieth",
    "fifty first",
    "fifty second",
    "fifty third",
    "fifty fourth",
    "fifty fifth",
    "fifty sixth",
    "fifty seventh",
    "fifty eighth",
    "fifty ninth",
    "sixtieth",
    "sixty first",
    "sixty second",
    "sixty third",
    "sixty fourth",
    "sixty fifth",
    "sixty sixth",
    "sixty seventh",
    "sixty eighth",
    "sixty ninth",
    "seventieth",
    "seventy first",
    "seventy second",
    "seventy third",
    "seventy fourth",
    "seventy fifth",
    "seventy sixth",
    "seventy seventh",
    "seventy eighth",
    "seventy ninth",
    "eightieth",
    "eighty first",
    "eighty second",
    "eighty third",
    "eighty fourth",
    "eighty fifth",
    "eighty sixth",
    "eighty seventh",
    "eighty eighth",
    "eighty ninth",
    "ninetieth",
    "ninety first",
    "ninety second",
    "ninety third",
    "ninety fourth",
    "ninety fifth",
    "ninety sixth",
    "ninety seventh",
    "ninety eighth",
    "ninety ninth",
    "hundredth",
  ];
  let Zipcode = /^[a-zA-Z0-9]{5,6}$/;
  let userData = decodeToken();
  let dispatch = useDispatch();
  let my_token = localStorage.getItem("auth-token");
  let useractive = localStorage.getItem("useractive");
  const headers = { Authorization: "Bearer " + my_token };
  const fref = useRef();
  const lref = useRef();
  const scref = useRef();
  const hcref = useRef();
  const ceref = useRef();
  const ajref = useRef();
  const fbaref = useRef();
  const imgref = useRef();
  const childref = useRef();
  const childrel = useRef();
  const emref = useRef();
  const phref = useRef();
  const zref = useRef();
  const userRef = useRef();
  let uimage = imageUrl;
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryParams = Object.fromEntries(urlSearchParams.entries());
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [Firth, setFirth] = useState("Yes");
  const [selectchild, setselectchild] = useState(0);
  const [selectfb, setSelectfb] = useState("");
  const [selectce, setSelectce] = useState("");
  const [selectaj, setSelectaj] = useState("");
  const [selectfa, setSelectfa] = useState("");
  const [selectffb, setSelectffb] = useState("");
  const [showaddperson, setshowaddperson] = useState(true);
  const [error, seterror] = useState(false);
  const [file, setFile] = useState("");
  const [childList, setChildList] = useState([]);
  const [userType, setUserType] = useState([]);
  const [homeschool, sethomeschool] = useState([]);
  const [imgflag, setimgflag] = useState(false);
  const [inputlist, setinputlist] = useState([]);
  const [childlen, setChildlen] = useState(0);
  const [zipcode, setZipcode] = useState("");
  const [countryName, setCountryName] = useState("");
  const [status, setStatus] = useState(false);
  const [selectTimeZone, setSelectTimeZone] = useState("")
  const [Bio, setBio] = useState("");
  const navigate = useNavigate();
  const [zipCode, setZipCode] = useState("");
  const [stripeData, setStripeData] = useState([])
  const location = useLocation();
  const state = location.state;
  const [address, setAddress] = useState("");
  const [locationState, setLocationState] = useState({
    country: "",
    state: "",
    city: "",
    zipcode: "",
    latitude: "",
    longitude: "",
    short_key: "",
  });
  const [loading, setLoading] = useState(false);
  const [userTypeData, setUserTypeData] = useState([]);
  const [user, setUser] = useState([]);
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [timeZone, setTimeZone] = useState([]);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const [phoneno, setPhones] = useState("");
  const [reducedPhone, setReducedPhone] = useState("");
  const [stripe, setStripe] = useState(false);
  const [stripeAccountId, setStripeAccountId] = useState("");
  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      url: "",
    },
  ]);

  const GetFriendLink = () => {
    let data = {
      friend_id: getFriendId,
    };
    makePostRequest(URLS.getFriendLinkUrl, null, data, null)
       .then((res) => {
        // console.log(res,"friend link apis res.........")
           if (res.code === 201) {
            window.location.href = "/purchase-pages-friend";
           }
       })
       .catch((error) => {
          console.log(error);
       });
  };
  //--start these functios are use for image
  const onChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const beforeCrop = (file) => {
    if (
      file.name.toLowerCase().includes("jpg") ||
      file.name.toLowerCase().includes("png") ||
      file.name.toLowerCase().includes("jpeg")
    ) {
      setimgflag(false);
      return true;
    } else {
      setimgflag(true);
      return false;
    }
  };

  // this function use for remove profile image
  const onRemove = async () => {
    setimgflag(false);
    makePostRequest(URLS.profileImageDelete, null, {}, null)
      .then((res) => {
        toastifyMessage(res.message, "success")
      }).catch((error) => {
        console.log("error::", error)
      })
  };
  //--end these functios are use for image

  //--function for fetching user detail
  const handleshowupload = () => {
    setLoading(true);
    let data = {
      id: userData.userAuth.id,
    };
    makePostRequest(URLS.userfetchdata, null, data, null).then((response) => {
      console.log("res:::.........................", response)
      setLoading(false);
      if (response?.data?.uesrData?.firstName !== null) {
        setStatus(true);
      }
      if (response?.data?.uesrData?.countryCode) {
        // setCountryCode(response?.data?.uesrData?.country_dial_code?.toLowerCase())
        setCountryNamme(response?.data?.uesrData?.country_dial_code?.toLowerCase())
        
      }
      setSelectTimeZone(response?.data?.uesrData?.timezone)
      setfirstname(
        response?.data?.uesrData?.firstName === null
          ? ""
          : response?.data?.uesrData?.firstName
      );
      setlastname(
        response?.data?.uesrData?.lastName === null
          ? ""
          : response?.data?.uesrData?.lastName
      );
      setBio(
        response?.data?.uesrData.bio_about == null
          ? ""
          : response?.data?.uesrData.bio_about
      );
      const result = response?.data?.uesrData?.tbl_users_roles?.map(item => ({
        value: item?.tbl_user_role?.id,
        label: item?.tbl_user_role?.role_name
      }));
      setUserTypeData(result);
      setUserType(result.map(val => val.value));
      response.data.uesrData.faith_background === null
        ? setFirth("Yes")
        : setFirth(response.data.uesrData.faith_background);
      response.data.uesrData.faith_affiliation &&
        setSelectfa(response.data.uesrData.faith_affiliation);
      setSelectffb(
        response.data.uesrData.family_faith_background === null
          ? ""
          : response.data.uesrData.family_faith_background
      );
      setSelectce(
        response.data.uesrData.child_education_character_value === null
          ? ""
          : response.data.uesrData.child_education_character_value
      );
      setSelectaj(
        response.data.uesrData.any_additional_job === null
          ? ""
          : response.data.uesrData.any_additional_job
      );
      setselectchild(
        response.data.uesrData.number_of_child === null || parseInt(response.data.uesrData.number_of_child) === 0
          ? 0
          : response.data.uesrData.number_of_child
      );
      setChildList(
        response.data.childList === null ? [] : response.data.childList
      );
      setSelectfb(
        response.data.uesrData.family_background === null
          ? ""
          : response.data.uesrData.family_background
      );
      setChildlen(response?.data?.childList?.length);
      setFileList([{ url: uimage + response.data.uesrData.photo }]);
      response.data.uesrData.stripe_accountId && setStripeAccountId(response.data.uesrData.stripe_accountId)
      const hs = response.data.uesrData.tbl_ideal_homeschools.map((item) => {
        return item.name;
      });
      sethomeschool(hs);
      if (file.includes("undefined") || file.includes("null")) {
        setFile("");
      }
      setAddress(response?.data?.uesrData?.address);
      let addressObj = {
        country: response?.data?.uesrData?.country,
        city: response?.data?.uesrData?.city,
        zipcode: response?.data?.uesrData?.zipcode,
        state: response?.data?.uesrData?.state,
        latitude: response?.data?.uesrData?.latitude,
        longitude: response?.data?.uesrData?.longitude,
      };
      setLocationState(addressObj);
      setZipcode(response?.data?.uesrData?.zipcode === null ? "" : response?.data?.uesrData?.zipcode);
      setEmail(response?.data?.uesrData?.email);
      setPhones(response?.data?.uesrData?.countryCode + response?.data?.uesrData?.phone);
      setReducedPhone(response?.data?.uesrData?.phone);
      setCountryCode(response?.data?.uesrData?.countryCode);
      setCountryName(response?.data?.uesrData?.country_name)
    });
  };
  function setchildno() {
    if (childlen > 0) {
      const values = [];
      for (var i = 0; i < childlen; i++) {
        values.push({
          id: childList[i].id,
          firstName: childList[i].firstName,
          lastName: childList[i].lastName,
          flag: true,
        });
      }
      setinputlist(values);
    }
  }
  const handlePhoneChange = (value, country) => {
    console.log("country, value", country, value);
  
    // Remove the country dial code from the phone number
    const reducedPhone = value.replace(country.dialCode, '');
    console.log("reduced", reducedPhone);
    // Save the reduced phone number in the state
	  setCountryNamme((country?.countryCode)?.toUpperCase())
    setPhones(value);
    setReducedPhone(reducedPhone)
    setCountryCode(`+${country.dialCode}`); // Save the country code with "+" prefix
  };
  
  console.log("phones", phoneno, reducedPhone)
  console.log("countryCode", countryCode)

  //===Function for uploading  user image===//
  function uploadimage(cropimage) {
    const formData = new FormData();
    formData.append("photo", cropimage ? cropimage : "");
    formData.append("id", userData.userAuth.id);
    makeFormDateRequset(URLS.useruploadprofile, formData).then((response) => {
      if (response?.code === 200) {
        setFileList([{ url: uimage + response.data.photo }]);
        toastifyMessage(response.message, "success");
        setTimeout(() => {
          makeGetRequest(URLS.token, null, { headers }).then((re) => {
            localStorage.setItem("auth-token", re?.data?.authorization);
          });
        }, 500);
        setTimeout(() => {
          let data = {
            id: userData.userAuth.id,
          };
          makePostRequest(URLS.userfetchdata, null, data, null).then((res) => {
            if (res?.code === 200) {
              dispatch(setUserData({ name: res.data.uesrData.firstName, image: res.data.uesrData.photo }))
            }
          });
        }, 700);
      }
    });
  }
  // ------------start input handle  function
  const handleInputChange = (index, e) => {
    const values = [...inputlist];
    const updatedValue = e.target.name;
    values[index][updatedValue] = e.target.value;
    setinputlist(values);
  };

  const handleZipCode = (e) => {
    setZipcode(e.target.value);
  }

  useEffect(() => {
    makeGetRequest(URLS.timeZone, null, null, null)
      .then((res) => {
        // console.log(res, "timezoneList Api res")
        setTimeZoneList(res.data)
      })
  }, [])


  
  useEffect(() => {
    setSelectTimeZone(""); 
    const fetchTimezone = async () => {
      if (zipcode !== "") {
        try {
          const geoResponse = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode.replace(" ", "")}&region=US&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`);
          
          if (geoResponse.data.status === "OK") {
            const { lat, lng } = geoResponse.data.results[0].geometry.location;
            const timezoneResponse = await axios.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=1331161200&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`);
            
            const tz = timezoneResponse.data.timeZoneId === "Asia/Calcutta" ? "Asia/Kolkata" : timezoneResponse.data.timeZoneId;
            setTimeZone(tz);
  
          } else if (geoResponse.data.status === "ZERO_RESULTS") {
            setTimeZone("");
            // toastifyMessage("Invalid zip code. Please enter a valid zip code.", "error");
          } else {
            setTimeZone("");
          }
        } catch (error) {
          console.error("Error fetching data", error);
        }
      }
    };
  
    fetchTimezone();
  }, [zipcode]);
  
  const handlechild = () => {
    const values = [];
    if (childlen <= selectchild) {
      for (var i = 0; i < childlen; i++) {
        values.push({
          id: childList[i]?.id || null,
          firstName: childList[i]?.firstName || "",
          lastName: childList[i]?.lastName || "",
          flag: true,
        });
      }
      for (var j = childlen; j < selectchild; j++) {
        values.push({
          firstName: "",
          lastName: "",
        });
      }
      setinputlist(values);
    } else {
      toastifyMessage(
        "You cannot reduce the number of students. Please delete the student profile first.",
        "error"
      );
    }
  };
  const handlehs = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      sethomeschool([...homeschool, value]);
    } else {
      sethomeschool(homeschool.filter((item) => item !== value));
    }
  };
  // -------------end input handle
  // functions for error handling and user data submission//==
  const handlesavedata = () => {
    if (fileList === "") {
      setimgflag(true);
      imgref.current.focus();
    } else if (firstname?.length === 0) {
      seterror(true);
      fref.current.focus();
    } else if (lastname === "") {
      seterror(true);
      lref.current.focus();
    } else if (email === "") {
      emref.current.focus();
    } else if (!emailValidation()) {
      return;
    } else if (phoneno === "") {
      phref.current.focus();
    } else if (!phoneValidation()) {
      return;
    }
     else if (zipcode === "") {
      zref.current.focus();
      seterror(true);
    } 
    else if (!Zipcode.test(zipcode?.replace(/\s/g, ""))) {
      zref.current.focus();
      seterror(true);
      setZipCode("Invalid zip code");
    } else if (userTypeData.length <= 0) {
      seterror(true);
      userRef.current.focus()
    } else if (selectchild < 0) {
      seterror(true);
      scref.current.focus();
    } else if (selectchild > 0 && inputlist?.length === 0) {
      seterror(true);
    } else if (selectchild > 0 && inputlist.filter((item) => item.firstName === "")?.length > 0) {
      seterror(true);
      childref.current.focus();
    } else if (selectchild > 0 && inputlist.filter((item) => item.lastName === "")?.length > 0) {
      seterror(true);
      childrel.current.focus();
    } else {
      setLoading(true);
      var objecthomeschool = [];
      for (var i = 0; i < homeschool?.length; i++) {
        var obj = { name: homeschool[i] };
        objecthomeschool.push(obj);
      }
      var tempList = [];
      for (var j = 0; j < inputlist?.length; j++) {
        if (inputlist[j].id != null) {
          var x = inputlist[j];
          x.flag = true;
          tempList.push(x);
        } else {
          tempList.push(inputlist[j]);
        }
      }
      let data = {
        id: userData.userAuth.id,
        firstName: capitalizeWords(firstname.replaceAll("_", " ")),
        lastName: capitalizeWords(lastname?.replaceAll("_", " ")),
        number_of_child: selectchild <= 0 ? 0 : parseInt(selectchild),
        child_list: tempList,
        ideal_home_schools: objecthomeschool,
        faith_background: Firth,
        family_faith_background: selectffb,
        faith_affiliation: selectfa,
        child_education_character_value: selectce,
        any_additional_job: selectaj,
        address: address,
        timezone: selectTimeZone,
        city: locationState?.city,
        state: locationState?.state,
        short_name: locationState?.short_key,
        zipcode: zipcode ? zipcode : locationState.zipcode,
        country: locationState.country,
        bio_about: Bio,
        phone: reducedPhone,
        countryCode: countryCode,//+1
        country_dial_code:counryName,//US
        email: email,
        user_type_role_id: userTypeData?.map(val => val.value) || []
      };
        console.log("payload data", data)
      if (locationState.latitude) {
        data.latitude = locationState.latitude;
      }
      if (locationState.longitude) {
        data.longitude = locationState.longitude;
      }
      // Api call for user data submission
      makePostRequest(URLS.userupdateprofile, null, data, null)
        .then((response) => {
          toastifyMessage(response.message, "success");
          var payload = {
            resource_id: "",
          };
          makePostRequest(URLS.getChildList, null, payload, null).then((res) => {
            dispatch(getchildList(res.data))
          });
          setLoading(false);
          if(selectedPlan === "free"){
            GetFriendLink();
          }
          if (parseInt(selectchild) === 0) {
            if (response.code === 200) {
              makeGetRequest(URLS.token, null, { headers }).then((re) => {
                let my_token = re?.data?.authorization;
                localStorage.setItem("auth-token", re?.data?.authorization);
                var decoded = my_token ? jwt_decode(my_token) : "";
                // console.log(decoded.userAuth.firstName)
                localStorage.setItem("fristname", decoded.userAuth.firstName);
              });
          //     if (getsendGroupId) {
          //        navigate("/yourgroup");
          //   }
          //  else if(getInviteLinkId){
          //     navigate("/purchase-pages-friend");
          //   }
          //    else {
              setTimeout(() => {
                if (state === "paid") {
                  window.location.href = "/subscription";
                }
                else if (getInviteLinkId) {
                  navigate("/purchase-pages-friend");
                  window.location.href = "/purchase-pages-friend"
                }
                else if (getsendGroupId) {
                         navigate("/yourgroup");
                   }
                 else {
                  localStorage.setItem("scrollvalue", true)
                   window.location.href = "/";
                }
              }, 1000);
            }
          // }
          } else {
            if (response.code === 200) {
              makeGetRequest(URLS.token, null, { headers }).then((re) => {
                localStorage.setItem("auth-token", re?.data?.authorization);
              });
              
              // if (getsendGroupId) {
              //   navigate("/yourgroup");
              // } else if (getInviteLinkId) {
              //   navigate("/purchase-pages-friend");
              // }
              // else{
              setTimeout(() => {

                //  if (userData?.userAuth?.firstName && parseInt(selectchild) === parseInt(childlen)) {
                //   navigate("/");
                // }
                if (getInviteLinkId) {
                  navigate("/purchase-pages-friend");
                  window.location.href = "/purchase-pages-friend"
                }
                else if (getsendGroupId) {
                         navigate("/yourgroup");
                   }
                  else if (userData?.userAuth?.firstName && parseInt(selectchild) === parseInt(childlen)) {
                    navigate("/");
                  }

                 else {
                  navigate("/child-info/0", { state });
                }

              }, 2000);
            // }
            } else {
              toastifyMessage(response.data.message, "error");
            }
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };
  // this function use for  google adress
  const getGoogleAddressStreetOne = () => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      let fullAddress = "";
      if (place?.address_components) {
        let addressComponent = place?.address_components;
        addressComponent.forEach((component) => {
          fullAddress += component.long_name + ", ";
        });
        fullAddress = fullAddress.slice(0, -2);
        setAddress(fullAddress);
        let obj = {
          country: "",
          city: "",
          zipcode: "",
          state: "",
          latitude: "",
          longitude: "",
          short_key: ""
        };
        obj.latitude = place.geometry.location.lat();
        obj.longitude = place.geometry.location.lng();
        for (let i = 0; i < addressComponent?.length; i++) {
          if (addressComponent[i].types[0] === "locality") {
            obj.city = addressComponent[i].long_name;
          }
          if (addressComponent[i].types[0] === "postal_code") {
            obj.zipcode = addressComponent[i].long_name;
          }
          if (addressComponent[i].types[0] === "administrative_area_level_1") {
            obj.state = addressComponent[i].long_name;
            obj.short_key = addressComponent[i].short_name
          }
          if (addressComponent[i].types[0] === "country") {
            obj.country = addressComponent[i].long_name;
          }
        }
        setZipcode(obj.zipcode);
        setLocationState(obj);
      }
    });
  };

  // this function i use for email validate
  const emailValidation = () => {
    if (Emailvalidation.test(email) === false) {
      seterror(true);
      setEmailErr("Please enter valid email");
      emref.current.focus();
      return false;
    } else if (Emailvalidation.test(email) === true) {
      setEmailErr("");
      return true;
    }
  };

  //this function for phone number alidation
  const phoneValidation = () => {
    const Phonevalidation = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
    if (Phonevalidation.test(phoneno) === false) {
      seterror(true);
      setPhoneErr("Please enter valid number");
      phref.current.focus();
      return false;
    }
    else if (Phonevalidation.test(phoneno) === true) {
      setPhoneErr("");
      return true;
    }
  }

  // this function use for stripe data
  const getAccounts = () => {
    makePostRequest(URLS.getStripeAccount, null, {}, null)
      .then((res) => {
        const arr = [];
        res?.data?.forEach((val, index) => {
          if (val?.stripe_setup_done) {
            return arr.push({ value: val?.id, label: val?.email, id: val?.stripe_account_id, status: true, payouts_enabled: val?.payouts_enabled, charges_enabled: val?.charges_enabled })
          } else {
            return arr.push({ value: val?.id, label: val?.email, id: val?.stripe_account_id, status: false, payouts_enabled: val?.payouts_enabled, charges_enabled: val?.charges_enabled })
          }
        })
        setStripeData(arr)
      }).catch((error) => {
        console.log("error::", error)
      })
  }

  // this function is use get the user tyep
  const handleuser = () => {
    makeGetRequest(URLS.userroleget, null, null)
      .then((res) => {
        const result = res.data?.map(item => ({
          value: item?.id,
          label: item?.role_name
        }));
        setUser(result)
      })
      .catch((error) => { })
  }
  // --- these functios are use for get and update the data with useEffect
  useEffect(() => {
    handlechild();
    // eslint-disable-next-line
  }, [selectchild]);

  useEffect(() => {
    if (selectfb === "no") {
      setshowaddperson(false);
      setSelectfb("no");
      setSelectffb("");
      setSelectfa("");
    } else {
      setshowaddperson(true);
      setSelectfb("Yes");
    }
    // eslint-disable-next-line
  }, [selectfb]);

  useEffect(() => {
    handleuser()
    if (useractive === "") {
      navigate("/sign-in");
    }
    getAccounts()
    // makeGetRequest(URLS.timeZone, null, null, null)
    //   .then((res) => {
    //     setTimeZone(res.data)
    //   })
    handleshowupload();
    document.body.classList.add("parentragistrationform");
    return () => {
      document.body.classList.remove("parentragistrationform");
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setchildno();
    // eslint-disable-next-line
  }, [childList]);

  useEffect(() => {
    if (!loading) {
      getGoogleAddressStreetOne();
    }
    // eslint-disable-next-line
  }, [!loading]);

  useEffect(() => {
    if (queryParams.acc) {
      navigate("/serve-dashboard");
    }
  }, [queryParams.acc, navigate]);
  // ----------------end get and update the data with useEffect
  return (
    <>
      {loading && (
        <div className="sharepopupOuter backgroundOpacity1">
          <FourDotsLoader />
        </div>
      )}
      {
        stripe && <StripePopup datar={() => setStripe(!stripe)} user={true} data={stripeData} current={stripeAccountId} />
      }
      <Head title="Create Profile"
        content="The growth of homeschooling and microschools (now over 6M children nationally), supplementary educational services (tutors, test prep, camps, etc.) and school choice (ESAs, vouchers, tax credits) has created a large market of educators/parents with full flexibility to personalize learning.  However, it is very overwhelming and challenging to sort through all the options to find the right fit for their child."
        name="Create Profile" ></Head>
       
      <div className={`${status === true ? "col-lg-10" : "containerparent"}`}>
        <div className="owncontainer">
          <div className="profileStripSetings">
            {status === true ? (
              <h2 className="profile">Profile Settings</h2>
            ) : (
              <>
                <h2 className="comunity">
                  Thank you for joining our<br></br> MatchED community.{" "}
                </h2>
                <h3 className="parent-form-para">
                  In order to personalize your experience, please provide us with
                  the optional information requested below.
                </h3>
                <span className="parent-text">
                  *Any profile information you share will be kept confidential and
                  will only be used to help customize your experience while using
                  MatchED's platform.
                </span>
                <h4 className="profile">Complete Your Profile</h4>
              </>
            )}
            {/* {console.log("countryname", countryName)} */}
            {(countryName === "US" || countryName === "CA") ?
              <div className="profilestripSetings"><button
                className="strip-activation"
                stripe="true"
                activation="true"
                onClick={() => setStripe(true)}
              >

                {stripeData.length > 0 ? "Manage Stripe Account" : "Stripe Activation"}
              </button></div>
              : ""
            }
          </div>
          <form ref={imgref} onSubmit={(event) => event.preventDefault()}>
            <div className="profile-div">
              <div className="input-file1">
                <div className="upload11" >Upload photo</div>
              </div>
              <ImgCrop
                cropperProps={{ restrictPosition: false }}
                cropShape="round"
                showGrid
                minZoom={0.2}
                zoomSlider
                rotationSlider
                showReset
                // aspect={2 / 1.9}
                aspect={10.5 / 9}
                height={1200}
                beforeCrop={(file) => beforeCrop(file)}
                onModalOk={(cropedimg) => uploadimage(cropedimg)}
              >
                {fileList ? (
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-circle"
                    onChange={onChange}
                    onRemove={onRemove}
                    // beforeUpload={beforeUpload}
                    fileList={fileList}
                  >
                    {fileList === "" && (
                      <div className="text">
                        {" "}
                        <PlusOutlined /> upload
                      </div>
                    )}
                  </Upload>
                ) : (
                  <>
                    <Upload
                      action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                      listType="picture-circle"
                      onChange={onChange}
                    >
                    </Upload>
                  </>
                )}
              </ImgCrop>
            </div>
            {imgflag === true ? (
              <span className="error"> Only png, jpg, jpeg are allowed</span>
            ) : null}

            <div className="form-container-parent">
              <div className="row">
                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                  <label className="form-label0">
                    First Name<span className="start-color">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control input-control"
                    id="input1"
                    ref={fref}
                    onChange={(e) => setfirstname(e.target.value.trimStart())}
                    value={firstname}
                  />
                  {error && firstname === "" ? (
                    <span className="error1"> Please enter first name </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-6 col-lg-6">
                  <label className="form-label0">
                    Last Name<span className="start-color">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control input-control"
                    id="input2"
                    ref={lref}
                    onChange={(e) => setlastname(e.target.value)}
                    value={lastname}
                  />
                  {error && lastname === "" ? (
                    <span className="error1"> Please enter last name </span>
                  ) : (
                    ""
                  )}

                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label className="form-label0">
                    Email<span className="start-color">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control input-control"
                    id="inputemail"
                    ref={emref}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    readonly="readonly"
                  />
                  {email === "" ? (
                    <span className="error1"> Please enter email. </span>
                  ) : emailErr !== "" ? (
                    <span className="error1">{emailErr}</span>
                  ) : ""}

                </div>
                <div className="col-lg-6">
                  <label className="phone-num-inner">
                    Phone No.<span className="start-color">*</span>
                  </label>
                  <PhoneInput
                            country={'us'}
                            value={phoneno}
                            onChange={handlePhoneChange}
                            inputProps={{
                                name: 'phone',
                                required: true,
                                autoFocus: true
                            }}
                            enableSearch={true}
							placeholder="Phone Number"
                        />
                  {
                    phoneno === "" ? (
                      <span className="error1"> Please enter phone no. </span>
                    ) : phoneErr !== "" ? (
                      <span className="error1"> {phoneErr
                      } </span>
                    ) : (
                      ""
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label className="form-label0">
                    Address
                  </label>
                  <input
                    type="text"
                    name="address"
                    className="form-control input-control"
                    placeholder="Enter address"
                    value={address ? address : ""}
                    onChange={(e) => setAddress(e.target.value)}
                    ref={inputRef}
                  />
                   
                      <div className="facustomeI"
                     style={{
                      position: "absolute",
                      top: "80px",
                    }}
                      >i<div className="tootlippillerinfo">
                      Please provide your city and state in the address.This will help us gather location data for the city and state you provide.
                      </div></div>
                </div>
                <div className="col-lg-6">
                  <div className="zip-code-inner">
                    <label className="form-label0">
                      Zip Code<span className="start-color">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control input-control"
                      id="inputphone"
                      onChange={handleZipCode}
                      value={zipcode}
                      ref={zref}
                    />
                    <span className="select-one">
                      <div className="facustomeI">i<div className="tootlippillerinfo">
                        For users outside the U.S. and Canada: If you don't have a zip code, please enter '111111' to continue.
                      </div></div></span>
                    <span className="error1">
                      {error && (zipcode === "" ? <p>Please enter zip code</p> : !Zipcode.test(zipcode?.replace(/\s/g, "")) ? <p>{zipCode}</p> : "")}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row py-3" >
                <div className="col-lg-6">
                  <label className="form-label2">
                    Time Zone
                    {/* <span className="start-color">*</span> */}
                  </label>
                  {zipcode === "111111"  || timeZone === "" ?
                    <select
                      className="form-control"
                      onChange={(e) => setSelectTimeZone(e.target.value)} value={selectTimeZone}>
                      <option value="">Choose a time zone</option>
                      {timeZoneList && timeZoneList.length > 0 && timeZoneList?.map((temp) => (
                        <option value={temp.nameTimeZone}>{temp.nameTimeZone}</option>
                      ))}
                    </select>
                    :
                    <input
                      type="text"
                      className="form-control input-control"
                      id="inputphone"
                      value={timeZone}
                    />
                  }
                  <span className="select-one">
                    <div className="facustomeI">i<div className="tootlippillerinfo">
                      The Time zone will be updated based on your zip code , You can also select time zone , if zip code is not available. 
                    </div></div></span>


                  {error && "" ? (
                    <span className="error-span controlinput">
                      Please enter your grade level
                    </span>
                  ) : (
                    ""
                  )}
                </div>
                <div className=" col-lg-6 mul-sel-div mb-4">
                  <h3>
                    User Type<span className="start-color">*</span>{" "}
                    <span className="select-one"> (select one or multiple)</span>
                  </h3>
                  <Select
                    value={userTypeData}
                    options={user}
                    isMulti
                    selectMultiple={true}
                    onChange={(e) => setUserTypeData(e)}
                    ref={userRef}
                  />
                  {(error && userTypeData.length <= 0) ? (
                    <span className="error-span controlinput">
                      Please choose user type.
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="rows">
                <div className="biotextareaouter">
                  <div className="radio-div1 mb-2">
                    <h3 className="form-label2">About Yourself</h3>
                    <div className="radio-btn-handle biotextarea">
                      <div className="form-check form-check-inline">
                        <textarea
                          value={Bio}
                          onChange={(e) => setBio(e.target.value)}
                          rows={5}
                          cols={150}
                          style={{ width: "100%", borderRadius: "25px" }}
                          className="input-control"
                          id="input1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 ">
                  <label className="form-label0 mt-2">
                    How many students are you creating profiles for?
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="input3"
                    ref={scref}
                    onChange={(e) => {
                      if (e.target.value <= 50) {
                        setselectchild(e.target.value)
                      }
                      else {
                        toastifyMessage("You can add upto 50 students per account", "error")
                      }
                    }}
                    value={selectchild}
                  />
                  {error && selectchild < 0 ? (
                    <span className="error1">
                      {" "}
                      Please enter valid student number{" "}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {inputlist &&
              inputlist.map((val, index) => {
                return (
                  <>
                    <div className="first-child-detail-con">
                      <div className="row">
                        <h4 className="first-child-h4">
                          {childdisplay[index]} Student's name
                        </h4>
                        <div className="col-lg-6">
                          <label className="form-label2">
                            First Name
                            <span className="start-color">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            id="input1"
                            name="firstName"
                            ref={childref}
                            onChange={(event) => handleInputChange(index, event)}
                            value={val.firstName}
                          />
                          {error && val.firstName === "" ? (
                            <span className="error1">
                              {" "}
                              Please enter first name{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-lg-6">
                          <label className="form-label2">Last Name<span className="start-color">*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                            id="input2"
                            name="lastName"
                            ref={childrel}
                            onChange={(event) => handleInputChange(index, event)}
                            value={val.lastName}
                          />
                          {error && val.lastName === "" ? (
                            <span className="error1">
                              {" "}
                              Please enter lastname.{" "}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>{" "}
                    </div>
                  </>
                );
              })}
            <div className="row">
              <div className="col-lg-12">
                <div className="f-con">
                  <h3 className="form-h3 mt-3">
                    What would your ideal homeschool schedule look like? <span className="select-one">
                      (select all that apply)
                    </span>{" "}
                  </h3>

                  <div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineRadio111"
                        ref={hcref}
                        value="Half Day"
                        checked={homeschool.find(
                          (element) => element === "Half Day"
                        )}
                        onChange={handlehs}
                      />
                      <label
                        className="form-check-label  handle-radio"
                        htmlFor="inlineRadio111"
                      >
                        Half Day
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineRadio222"
                        value="Full Day"
                        checked={homeschool.find(
                          (element) => element === "Full Day"
                        )}
                        onChange={handlehs}
                      />
                      <label
                        className="form-check-label  handle-radio"
                        htmlFor="inlineRadio222"
                      >
                        Full Day
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineRadio333"
                        value="4 days week"
                        checked={homeschool.find(
                          (element) => element === "4 days week"
                        )}
                        onChange={handlehs}
                      />
                      <label
                        className="form-check-label  handle-radio"
                        htmlFor="inlineRadio333"
                      >
                        4 Days a Week
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineRadio444"
                        value="5 days week"
                        checked={homeschool.find(
                          (element) => element === "5 days week"
                        )}
                        onChange={handlehs}
                      />
                      <label
                        className="form-check-label  handle-radio"
                        htmlFor="inlineRadio444"
                      >
                        5 Days a Week
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineRadio555"
                        value="experimental"
                        checked={homeschool.find(
                          (element) => element === "experimental"
                        )}
                        onChange={handlehs}
                      />
                      <label
                        className="form-check-label  handle-radio"
                        htmlFor="inlineRadio555"
                      >
                        Experiential/Field Trip Based
                      </label>
                    </div>

                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="radio-div">
                    <h3 className="form-h31">
                      Does your family have a faith background?{" "} <span className="select-one">(select one)</span>
                    </h3>
                    <div className="radio-btn-handle">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions1"
                          id="inlineRadio6"
                          checked={Firth === "No" ? true : false}
                          value="No"
                          onClick={(e) => setFirth(e.target.value)}
                        />
                        <label
                          className="form-check-label handle-radio"
                          htmlFor="inlineRadio6"
                        >
                          No
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions1"
                          id="inlineRadio66"
                          value="Yes"
                          checked={Firth === "Yes" ? true : false}
                          onClick={(e) => setFirth(e.target.value)}
                        />
                        <label
                          className="form-check-label handle-radio"
                          htmlFor="inlineRadio66"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {Firth !== "No" && showaddperson ? (
              <div className="container-2 mb-3">
                <div className="row">
                  <div className="col-lg-12">
                    <label className="form-label11">
                      If yes, what is your faith affiliation?
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="input3"
                      placeholder="Example: Lutheran, Catholic, Baptist, Nondenominational, etc."
                      ref={fbaref}
                      value={selectfa}
                      onChange={(e) => setSelectfa(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <h3 className="form-h32">
                      How important is it to you to integrate your family's faith
                      background into your student's education ?{" "}
                      <span className="select-one">(select one)</span>
                    </h3>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions2"
                        id="inlineRadio777"
                        value="Not Important"
                        checked={selectffb === "Not Important"}
                        onChange={(e) => setSelectffb(e.target.value)}
                      />
                      <label
                        className="form-check-label handle-radio"
                        htmlFor="inlineRadio777"
                      >
                        Not Important
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions2"
                        id="inlineRadio888"
                        value="Somewhat Important"
                        checked={selectffb === "Somewhat Important"}
                        onChange={(e) => setSelectffb(e.target.value)}
                      />
                      <label
                        className="form-check-label handle-radio"
                        htmlFor="inlineRadio888"
                      >
                        Somewhat Important
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions2"
                        id="inlineRadio999"
                        value="Very Important"
                        checked={selectffb === "Very Important"}
                        onChange={(e) => setSelectffb(e.target.value)}
                      />
                      <label
                        className="form-check-label handle-radio"
                        htmlFor="inlineRadio999"
                      >
                        Very Important
                      </label>
                    </div>

                  </div>
                </div>
              </div>
            ) : null}

            <div className="row">
              <div className="col-lg-12">
                <div className="radio-div-3 mb-3">
                  <h3 className="form-h3">
                    How important is it to you to integrate character and values
                    into your student’s education?
                  </h3>
                  <div className="radio-btn-handle">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions3"
                        id="inlineRadio000"
                        ref={ceref}
                        value="Not important"
                        checked={selectce === "Not important"}
                        onChange={(e) => setSelectce(e.target.value)}
                      />
                      <label
                        className="form-check-label handle-radio"
                        htmlFor="inlineRadio000"
                      >
                        Not Important
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions3"
                        id="inlineRadio321"
                        value="Somewhat important"
                        checked={selectce === "Somewhat important"}
                        onChange={(e) => setSelectce(e.target.value)}
                      />
                      <label
                        className="form-check-label handle-radio"
                        htmlFor="inlineRadio321"
                      >
                        Somewhat Important
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions3"
                        id="inlineRadio432"
                        value="very important"
                        checked={selectce === "very important"}
                        onChange={(e) => setSelectce(e.target.value)}
                      />
                      <label
                        className="form-check-label handle-radio"
                        htmlFor="inlineRadio432"
                      >
                        Very Important
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {userType.includes(2) ? (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="radio-div1">
                      <h3 className="form-h3">
                        Will you be a full-time homeschool teacher or do you have
                        an additional job?
                      </h3>
                      <div className="radio-btn-handle">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions4"
                            ref={ajref}
                            id="inlineRadiooo"
                            value="Full time"
                            checked={selectaj === "Full time"}
                            onChange={(e) => setSelectaj(e.target.value)}
                          />
                          <label
                            className="form-check-label handle-radio"
                            htmlFor="inlineRadiooo"
                          >
                            Full Time
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions4"
                            id="inlineRadio2o"
                            value="Additional job"
                            checked={selectaj === "Additional job"}
                            onChange={(e) => setSelectaj(e.target.value)}
                          />
                          <label
                            className="form-check-label handle-radio"
                            htmlFor="inlineRadio2o"
                          >
                            Working an Additional Job
                          </label>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="save-btn-parent">
              <button className="save-button11" onClick={handlesavedata}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default Parentrgistrationform;
