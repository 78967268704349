import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./SignIn.css";
import "./Signup.css";
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import "react-toastify/dist/ReactToastify.css";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import { toastifyMessage, Emailvalidation } from "../../utilities/CustomFunctions";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import Head from "../Layout.WebAppMate/head/Head";

//      * created on: 09-10-2023 by kajal
//      * /
const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;
function DeleteAccount() {
  const [passicon, setpassicon] = useState(false);
  const [email, setemail] = useState("");
  const [passwords, setpasswords] = useState("");
  const [errorphone, setErrorsPhone] = useState("");
  const [errorpass, setErrorsPass] = useState("");
  const [spin, setspin] = useState(false);
  const emailFocus = useRef();
  const passFocus = useRef();

  //== Functions start for handling capturing email and password for login==
  const hanldeEmail = (e) => {
    setemail(e.target.value);
    setErrorsPhone("");
  };

  const handlpasswords = (e) => {
    setpasswords(e.target.value);
    setErrorsPass("");
  };
  //== Function for error handling and submit delete data==
  const DeleteAccount = (e) => {
    e.preventDefault();
    if (email === "") {
      emailFocus.current.focus()
      setErrorsPhone("Please enter your email address.");
    } else if (!Emailvalidation.test(email)) {
      emailFocus.current.focus()
      setErrorsPhone("Please enter valid email address.");
    } else if (passwords === "") {
      passFocus.current.focus();
      setErrorsPass("Please enter your password.");
    } else if (passwords.length < 8 || passwords.length > 20) {
      passFocus.current.focus();
      setErrorsPass(
        "Please enter a password between 8 and 20 characters long."
      );
    } else {
      let data = {
        userName: email,
        password: passwords,
      };
      setspin(true);
      makePostRequest(URLS.deleteUserAccount, null, data, null)
        .then((res) => {
            console.log("delete response", res)
          if (res.code === 200) {
            setspin(false);
            toastifyMessage("Account deleted successfully.", "success");
          } else {
            setspin(false);
            toastifyMessage(res.message, "error");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
		document.body.classList.add("delete-account");
		return () => {
			document.body.classList.remove("delete-account");
		};
	}, []);

  return (
    <>
      <Head title="Delete-Account"
        content="MatchED assists parents and other educators to match children and young adults with Learning Pathway Resources aligned with their passion, content mastery and values to make learning fun, successful and inspiring.  Our ultimate vision is to make an expanding global ecosystem of Learning Pathway Resources available through low-friction, high-value matches that are personalized to every child."
        name="Heart Mind Soul Strength" ></Head>
      <form className="frm" onSubmit={DeleteAccount}>

        <div className="login">
          <div className="loginouter">
            <span className="successfulltext"> { } </span>
            {spin && (
              <div className="sharepopupOuter backgroundOpacity1">
                <FourDotsLoader />
              </div>
            )}
            <h1>Delete Account</h1>
            <div className="form-group">
              <input
                className="form-control"
                id="inputPassword"
                placeholder="Email"
                value={email}
                onChange={hanldeEmail}
                ref={emailFocus}
              />
            
            </div>
            <span className="error"> {errorphone} </span>
            <div className="form-group">
              <input
                type={passicon === false ? "password" : "text"}
                className="form-control"
                id="inputEmail"
                placeholder="Password"
                value={passwords}
                onChange={handlpasswords}
                ref={passFocus}
              />
            
              <span onClick={() => setpassicon(!passicon)} className="hideshow">
                {passicon === true ? <AiFillEye /> : <AiFillEyeInvisible />}
              </span>
            </div>
            <span className="error"> {errorpass} </span>

            <div className="col-lg-12 text-center" tyle={{ textDecoration: "none", color: "white" }}>
              <button type="submit" className="btn11"> Delete Account
              </button>
            </div>
            <div className="bottomText">
            </div>
            <div className="bottomText">
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
export default DeleteAccount;
