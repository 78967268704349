import React from "react";
import './Notification.css';
import { decodeToken, toastifyMessage } from "../../utilities/CustomFunctions";
import { makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { useDispatch, useSelector } from "react-redux";
import { getNotification, getOpenGlobalChat } from "../../store/reducers/userReducer";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { makePostRequestForAll } from "../../services/api";
import SingleChat from "../Chat.WebAppMate/SingleChat";
const secretKey = process.env.REACT_APP_DATA_ENCRYPT_DESCRYP_KEY;
// /*
//      * Notification function is used to do view all the notification
//      * @author Upendra Kumar <upendra.webappmate@gmail.com>
//      * created on: 05-5-2023
//      * /

const Notification = () => {
    let user_id = decodeToken().userAuth.id;
    let userStore = useSelector((state) => state.user);
    let cdate = new Date();
    const navigate = useNavigate()
    const [showMore, setShowMore] = useState(true);
    const [list, setList] = useState([]);
    const [index, setIndex] = useState(1);
    const [showspin, setshowspin] = useState(false);
    const [status, setStatus] = useState("all");
    const dispatch = useDispatch()

    // APi to get all the notification
    const allNotification = (status, page) => {
        setshowspin(true)
        let data = {
            "user_id": user_id,
            "status": status,
            "page": page
        }
        if (window.location.pathname?.includes("notification")) {
            data["action_id"] = window.location.pathname?.split("/")?.[2]
        }
        console.log("data", data)
        makePostRequest(URLS.notificationlistall, null, data, null)
            .then((res) => {
                setshowspin(false);
                console.log("all notification data",res.data)
                if (page === 1) {
                    setList(res.data.notifications)
                }
                else {
                    setList([...list, ...res.data.notifications])
                }
                if (res.data.remaining_count === 0) {
                    setShowMore(false)
                } else {
                    setShowMore(true)
                }
            })
            .catch((err) => {
                console.log(err)
                setshowspin(false);
            })
    }
    // this function  use for crete chat
    const AddUser = (id, userData) => {
        dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: false }))
        makePostRequestForAll(URLS.addChatUser, { chat_with: id })
            .then((res) => {
                if (res.code === 200) {
                    dispatch(getOpenGlobalChat({ ...userStore?.globalOpenChat, chatOpen: true, userMapId: res?.data?.id, userDetail: userData, senderId: id, group: 0, pageName: "notification", 
                        chat_by_block : res?.data?.block, 
                        block_by_other_person : res?.data?.chat_by_block,
                        chat_with_block: res?.data?.chat_with_block,
                    }))
                }
            })
            .catch((e) => {
                console.log("error:::::", e)
            });
    };
    useEffect(() => {
        if (window.location.pathname?.split("/")?.[2]) {
            let data = {}
            if (window.location.pathname?.includes("notification") || window.location.pathname?.includes("Notification")) {
                data["action_id"] = window.location.pathname?.split("/")?.[2]
            }
            makePostRequest(URLS.viewNotification, null, data, null).then(res => {
                console.log("notification data one ",res.data)
                if (res?.data?.notifications?.redriect_type === "notification") {
                    return;
                } else if (res?.data?.notifications?.redriect_type === "child") {
                    navigate(`/child-profile-page/${res?.data?.notifications?.save_id}`)
                }
                else if (res?.data?.notifications?.redriect_type === "chat") {
                    if (res?.data?.notifications?.notification_type === "NEW_CHAT_GROUP_MULTIPLE") {
                        // setUserMapId(res?.data?.notifications?.group_id);
                        // setChatOpen(true);
                        // setUserDetail({ "name": res?.data?.notifications?.user_details?.name, "image": res?.data?.notifications?.user_details?.photo })
                    } else {
                       // setUserDetail({ "name": res?.data?.notifications?.user_details?.name, "image": res?.data?.notifications?.user_details?.photo })
                        makePostRequestForAll(URLS.addChatUser, { chat_with: res?.data?.notifications?.friend_id })
                            .then((res) => {
                                if (res.code === 200) {
                                    // setUserMapId(res?.data?.id);
                                    // setChatOpen(true)
                                }
                            })
                            .catch((e) => {
                                console.log("error:::::", e)
                            });
                    }
                }
                else if (res?.data?.notifications?.redriect_type === "friend") {
                    navigate(`/user-profile/${res?.data?.notifications?.save_id}`)
                }
                else if (res?.data?.notifications?.redriect_type === "group") {
                    if(res?.data?.notifications?.notification_type==="Review_of_group")
                        navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(res?.data?.notifications?.save_id.toString(), secretKey).toString())}?go=${res?.data?.notifications?.id}`)
                    if (res?.data?.notifications?.action_status === "1") {
                        navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(res?.data?.notifications?.save_id.toString(), secretKey).toString())}?go=${res?.data?.notifications?.id}`)
                    } else {
                        navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(res?.data?.notifications?.save_id.toString(), secretKey).toString())}`)
                    }
                } else {
                    allNotification(status, index);
                }
            }).catch(e => {
                console.log("e", e)
            })
        } else {
            allNotification(status, index);
        }
        // eslint-disable-next-line
    }, [])
    useEffect(() => {
        if (index === 1) {
            setList([])
            allNotification(status, index)
        }
        else {
            allNotification(status, index)
        }
        // eslint-disable-next-line
    }, [status, index])

    // this function use for notification status
    const notificationStatus = (id, action) => {
        const data = {
            "notification_id": id,
            "status": action
        }
        makePostRequest(URLS.notificationStatus, null, data, null)
            .then((res) => {
                if (res.code === 200) {
                    toastifyMessage(res.message, "success")
                }
                else {
                    toastifyMessage(res.message, "warn")
                }
                makePostRequest(URLS.markSingleNotification, null, { "notification_id": id }, null)
                    .then((res) => {
                        makePostRequest(URLS.notificationlist, null, data, null)
                            .then((res) => {
                                console.log("res::::", res)
                                dispatch(getNotification({
                                    unreadCount: res.data.unread_notification,
                                    notification: res.data.notifications
                                }))
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                        allNotification(status, index)
                    }).catch((error) => {
                        console.log("error::::", error)
                    })
            }).catch((error) => {

                console.log("error::::", error)
            })
    }
    // this function is use for read more
    const ReadMore = ({ children }) => {
        const text = children[0];
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        const ReadSignleNotification = () => {
            const data = {
                "notification_id": children[2]
            }
            makePostRequest(URLS.markSingleNotification, null, data)
                .then((res) => {
                    if (res.code === 200) {
                        allNotification(status, index);
                        let data = {
                            user_id: user_id,
                        };
                        makePostRequest(URLS.notificationlist, null, data, null)
                            .then((res) => {
                                console.log("res::: notification ", res)
                                dispatch(getNotification({
                                    unreadCount: res.data.unread_notification,
                                    notification: res.data.notifications
                                }))
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                }).catch((error) => {
                    console.log("error::::", error)
                })
        }
        return (
            <>
            <p className="text" >
              <span onClick={() => {notificationClick( children[2], children[4]); ReadSignleNotification(); }} style={{ cursor: "pointer" }} > {isReadMore ? text.slice(0, 170) : text}</span> 
          
             {
                text.length > 170 ? <span onClick={toggleReadMore} className="read-or-hide" style={{ cursor: "pointer" }}>
                    {isReadMore && <span>...read more</span>}
                </span> : ""
            }
                 
                 </p>
            
            </>
        );
    };
    const notificationClick = (id, date) => {
        const data = {
            "notification_id": id
        }
        makePostRequest(URLS.getViewNotification, null, data)
            .then((res) => {
                console.log("resresresres--", res)
                if (res?.data?.notifications?.redriect_type === "no_action") {
                    return;
                } else if (res?.data?.notifications?.redriect_type === "group") {
                    if (res?.data?.notifications?.action_status === "1") {
                        navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(res?.data?.notifications?.save_id.toString(), secretKey).toString())}?go=${res?.data?.notifications?.id}`)
                    } else {
                        navigate(`/groupjoin/${btoa(CryptoJS.AES.encrypt(res?.data?.notifications?.save_id.toString(), secretKey).toString())}`)
                    }
                }
                else if (res?.data?.notifications?.redriect_type === "group")
                {

                }
                else if (res?.data?.notifications?.redriect_type === "chat_group") {
                    navigate(`/groupjoin/${res?.data?.notifications?.action_id}?passKey=chat`)
                }
                else if (res?.data?.notifications?.redriect_type === "subscriptions") {
                    navigate(`/my-subscriptions`)
                }
                else if (res?.data?.notifications?.redriect_type === "zendesk") {
                    navigate(`/ticket-detail-page`, { state: { ticketid: res?.data?.notifications?.action_id } })
                }
                else if (res?.data?.notifications?.redriect_type === "friend") {
                    navigate(`/user-profile/${res?.data?.notifications?.save_id}`)
                }
                else if (res?.data?.notifications?.redriect_type === "resource_details") {
                    if (res?.data?.notifications?.notification_type === "RESOURCE_REVIEW") {
                        navigate(`/full-description/${res?.data?.notifications?.save_id}?passKey=pass`)
                    } else {
                        navigate(`/full-description/${res?.data?.notifications?.save_id}`)
                    }
                }
                else if (res?.data?.notifications?.redriect_type === "friend_list" && res?.data?.notifications?.notification_type !== "NEW_CHAT_MESSAGE") {
                    navigate(`/user-profile/${res?.data?.notifications?.save_id}`)
                }
                else if (res?.data?.notifications?.redriect_type === "child") {
                    if (res?.data?.notifications?.action_status === "1") {
                        navigate(`/child-profile-page/${res?.data?.notifications?.save_id}?go=${res?.data?.notifications?.id}`)
                    } else {
                        navigate(`/child-profile-page/${res?.data?.notifications?.save_id}`)
                    }
                }
                else if (res?.data?.notifications?.redriect_type === "invoice_resource" || res?.data?.notifications?.redriect_type === "user_payment") {
                    navigate(`/server-payment-history`)
                }
                else if (res?.data?.notifications?.redriect_type === "mentor_payment") {
                    navigate(`/sale-history?go=${res?.data?.notifications?.save_id}&resource=${res?.data?.notifications?.save_id}&date=${res?.data?.notifications?.tbl_user_mentoring_session_datetim?.user_session_date}`)
                }
                else if (res?.data?.notifications?.redriect_type === "mentoring_booking_session") {
                    navigate(`/session-history`, {
                        state: {
                            status: "mentoring_booking_session",  
                                child_id : res?.data?.notifications?.child_id,
                                create_date : res?.data?.notifications?.notification_type === "MENTORING_SESSION_RESCHEDULED" ?  res?.data?.notifications?.updated_at : date, res_id : 
                            res?.data?.notifications?.save_id 
                        
                        }
                    })
                }
                else if (res?.data?.notifications?.redriect_type === "user_mentoring_payment") {
                    navigate(`/server-payment-history?go=${res?.data?.notifications?.save_id}`)
                }
                else if (res?.data?.notifications?.redriect_type === "resource_share") {
                    navigate(`/share-inspiration`)
                }
            }).catch(e => {
                console.log(e)
            })
    }
    useEffect(() => {
        document.body.classList.add("notification");
        return () => {
            document.body.classList.remove("notification");
        };
    }, [])

    return (<>
        <div className="col-lg-10 col-md-12 col-sm-12">
            <div className="notification-ul">
                <div className="notification-header">
                    <h1>Notifications</h1>
                </div>
                <ul>
                    <li><button className="btn-all" onClick={() => { setIndex(1); setStatus("all") }}>All</button></li>
                    <li><button className="btn-unread" onClick={() => { setIndex(1); setStatus("unread") }}>Unread</button></li>
                    <li><button className="btn-read" onClick={() => { setIndex(1); setStatus("read") }}>Read</button></li>
                </ul>
            </div>
            {showspin &&
                <div className="sharepopupOuter backgroundOpacity1">
                    <FourDotsLoader />
                </div>
            }
            {list && list.map((val, index) => {
                return (
                    <div className="notification-div">

                        <div className="notification-div-flex">
                            <ul >
                                <li>
                                    {val.status === "read" ? "" : <div className="point">  </div>}
                                    <div className="notification-img-box">
                                        <img className="img-fluid" src={val.photo} alt="user" loading="lazy" />
                                    </div>
                                </li>
                                <li> <ReadMore>{val.message} {val.id} {val?.created_at}</ReadMore></li>
                                <li>
                                    {
                                        val.action_status === "1" && <div className="acceptReject">
                                            <button className="accept-btn" onClick={() => notificationStatus(val.id, "accepted")}>Accept</button>
                                            <button className="reject-btn" onClick={() => notificationStatus(val.id, "rejected")}>Reject</button>
                                        </div>
                                    }
                                    {
                                        val.notification_type === "NEW_CHAT_MESSAGE" &&
                                        <div className="acceptReject">
                                            <button className="accept-btn" onClick={() => {
                                                AddUser(val?.message_from_user, { "name": val?.name, "image": val?.photo })
                                                // setMemberId(val?.message_from_user);
                                                // setUserDetail({ "name": val?.name, "image": val?.photo })
                                            }}>Chat</button>
                                        </div>
                                    }
                                </li>
                            </ul>
                            <h6>{new Date(val.created_at).getDate() === cdate.getDate() && new Date(val.created_at).getMonth() === cdate.getMonth() ? "today" : new Date(val.created_at).getDate() === cdate.getDate() - 1 && new Date(val.created_at).getMonth() === cdate.getMonth() ? "yesterday" : dayjs(val.created_at).format("MM/DD/YYYY HH:MM:ss")}</h6>
                        </div>
                    </div>
                )
            })}
            <div className="show-more px-3 mb-4 py-2">
                {showMore && <p className="text-center" onClick={() => setIndex(index + 1)}>Show more</p>}
            </div>
        </div>
        {
             userStore?.globalOpenChat?.chatOpen && userStore?.globalOpenChat?.pageName === "notification" &&
              <SingleChat statuspage={"notification"} groupName={userStore?.globalOpenChat?.userDetail} chatClose={() => dispatch(getOpenGlobalChat({
                chatOpen: false, userDetail: "", userMapId: "", senderId: "", group: "", pageName: "" }))}  userMapId={userStore?.globalOpenChat?.userMapId} senderId={userStore?.globalOpenChat?.senderId} group={userStore?.globalOpenChat?.group} getAllUser={() => { }} />
        }
    </>);
}
export default Notification;
