/**
 * Pyment success page and download invoice of subscription
 *
 * @author Gaurang Ghadiya <ghadiya.gaurang@webappmate.com>
 * created on: 22-05-2023
 */
import React, { useEffect, useState } from "react";
import "./SubscriptionVerify.css";
import { imageUrl, makeGetRequest, makePostRequest } from "../../services/api";
import { URLS } from "../../constants";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  style,
  forceDownload,
  toastifyMessage,
  decodeToken,
} from "../../utilities/CustomFunctions";
import { useNavigate } from "react-router-dom";
import FourDotsLoader from "../Common.WebAppMate/FourDoted";
import { incrementByAmount } from "../../store/reducers/cartReducer";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";

function SubscriptionVerify() {
  let flag = false;
  const dispatch = useDispatch()
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isRePayment, setIsRePayment] = useState(false)
  const [temporary_data, setTemporary_data] = useState("");
  const payLoad = decodeToken().userAuth?.timezone;
  let uimage = imageUrl;
  let navigate = useNavigate();
  const [otherData, setOtherData] = useState([]);

  const getFriendId = sessionStorage.getItem("friendID")

  const GetFriendLink = () => {
    let data = {
      friend_id: getFriendId,
    };
    makePostRequest(URLS.getFriendLinkUrl, null, data, null)
       .then((res) => {
           if (res.code === 201) {
            //window.location.href = "/purchase-pages-friend";
           }
       })
       .catch((error) => {
          console.log(error);
       });
  };


  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("zeroPrice") === "yes") {
      if (localStorage.getItem("signupData")) {
        setOtherData(JSON.parse(localStorage.getItem("signupData")));
      }
    } else {
      if (localStorage.getItem("otherData")) {
        setOtherData(JSON.parse(localStorage.getItem("otherData")));
      }
    }
    // eslint-disable-next-line
  }, [localStorage]);

  // Get user subscriptions data
  const getData = async () => {
    console.log(localStorage.getItem("unique_id"))

    const body = {
      unique_id: localStorage.getItem("unique_id"),
    };

    await makePostRequest(URLS.paymentConfirm, null, body, null)
      .then(async (response) => {
        console.log("res::::: paymentConfirm", response)
        let temp_request_data = [JSON.parse(response?.data?.temp_request_data
        )]
        setTemporary_data(temp_request_data)
        temp_request_data?.length > 0 &&
          temp_request_data?.forEach((val, i) => {
            val?.scheduleData?.forEach((v, i) => {
              v?.session_date_time?.forEach((va, i) => {
                if (va?.payment_pay_flag === false
                ) {

                  console.log("Your card", i)
                  flag = true;
                  return true;
                }
                return false;

              })
            })
          })
        if (flag === true) {
          setOpen(true)
        }
        await makeGetRequest(URLS.token, null, null).then((re) => {
          localStorage.setItem("auth-token", re?.data?.authorization);
        });
        if (response?.data) {
          setData(response?.data);
          GetFriendLink();
        } else {
          setIsRePayment(true)
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };
  //  useEffect(()=>{

  //  },[flag])
  console.log("flag", flag)
  console.log("open", open)
  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      getData();
    }, 4000);
    // eslint-disable-next-line
  }, [])
  console.log("temp data", temporary_data)
  useEffect(() => {
    if (localStorage.getItem("zeroPrice") === "yes") {
      localStorage.removeItem("signupcard");
      localStorage.removeItem("signstate");
      if (localStorage.getItem("cart")) {
        dispatch(incrementByAmount(JSON.parse(localStorage.getItem("cart"))?.scheduleData?.length));
      }
      return (() => {
        localStorage.removeItem("signupData");
      })
    } else {
      localStorage.removeItem("cart");
      localStorage.removeItem("state");
      dispatch(incrementByAmount(0))

      if (localStorage.getItem("cart")) {
        dispatch(incrementByAmount(JSON.parse(localStorage.getItem("cart"))?.scheduleData?.length));
      }
      return (() => {
        localStorage.removeItem("otherData");
      })

    }
    // eslint-disable-next-line
  }, [data]);

  //download invoice
  const downloadInvoice = () => {
    if (data.invoice_pdf_link) {
      let pdfFileName = data.invoice_pdf_link.split("/");
      forceDownload(uimage + data.invoice_pdf_link, pdfFileName[3]);
    } else {
      toastifyMessage(" Invoice not available for download", "error");
    }
  };
  const redirectToDetail = (id) => {
    navigate(`/full-description/${id}`);
  };
  const redirect = () => {
    if (data?.payment_for === "book_session" && otherData?.length === 0) {
      navigate("/search-page")
    }
  }
  useEffect(() => {
    document.body.classList.add("paymentsuccess");
    return () => {
      document.body.classList.remove("paymentsuccess");
    };
  }, []);

  return (
    <>
      <div className="col-lg-10">




        {isLoading ? (
          <div className="sharepopupOuter backgroundOpacity1">
            <FourDotsLoader />
          </div>
        ) : (
          <>

            {data?.payment_for === "subscription" && (
              <div className="purcahse-information">
                <h2>Payment Successful</h2>
                <div className="purchase-inner-information">
                  <div className="icontext">

                    <h4 className="pinformation">Purchase Information</h4>
                  </div>

                  <div className="sprice">
                    <label className="mentor-session">Title</label>
                    <span className="pricetext">Price</span>
                  </div>
                  <hr></hr>
                  {data?.temp_request_data &&
                    JSON.parse(
                      data?.temp_request_data
                    )?.subscription_list?.map((list) => {
                      return (
                        <>
                          <div className="sprice">
                            <label className="text-capitalize">
                              {list?.title + " "}
                              ({list?.payment_frequency_type})
                            </label>
                            <span>${list?.total_amount?.toFixed(2)}</span>
                          </div>
                          <br></br>
                        </>
                      );
                    })}
                  {data?.payment_for === "subscription" && data?.coupen_amount !== "0" &&
                    <div className="sprice mb-2">
                      <label className="text-capitalize">Coupon Amount{" "}:</label>
                      <span className="text-success">&nbsp; -${parseFloat(data?.coupen_amount)?.toFixed(2)}</span>
                    </div>
                  }
                  <div className="sprice">
                    <label>Tax Amount{" "}:</label>
                    <span>&nbsp;${data?.tax_amount?.toFixed(2)}</span>
                  </div>

                  <hr></hr>
                  <div className="totalamount">
                    <label>Total:</label>
                    <span>
                      {console.log("dataaa", data)}
                      {data?.payment_for === "subscription" ?
                        <>${(parseFloat(data?.total_amount) - parseFloat(data?.coupen_amount)).toFixed(2)}</> :
                        <> ${data?.total_amount?.toFixed(2)}</>}
                    </span>
                  </div>
                </div>

                <div>
                  <button className="dinvoice" onClick={downloadInvoice}>
                    Download Invoice
                  </button>
                </div>
                <div>
                  <button
                    className="dinvoice1"
                    onClick={() => navigate("/my-subscriptions")}
                  >
                    View Subscription
                  </button>
                </div>
              </div>
            )}
            {data?.payment_for === "resource_purchased" && (
              <div className="purcahse-information">
                <h2>Payment Successful</h2>
                <div className="purchase-inner-information">
                  <div className="icontext">
                    <h4 className="pinformation">Purchase Information</h4>
                  </div>
                  <div className="sprice">
                    <label className="mentor-session">Title</label>
                    <span className="pricetext">Price</span>
                  </div>
                  <hr></hr>
                  <div className="sprice">
                    <label>
                      {data?.temp_request_data &&
                        JSON.parse(data?.temp_request_data)?.resource_title}
                    </label>
                    <span>${data?.payment_amount?.toFixed(2)}</span>
                  </div>
                  <br></br>
                  <div className="sprice">
                    <label>TAX AMOUNT</label>
                    <span>${data?.tax_amount?.toFixed(2)}</span>
                  </div>
                  <br></br>
                  <hr></hr>
                  <div className="totalamount">
                    <label>Total{" "}:</label>
                    <label>&nbsp;${data?.total_amount?.toFixed(2)}</label>
                  </div>
                </div>
                <div>
                  <button className="dinvoice" onClick={downloadInvoice}> Download Invoice </button>
                </div>
                <div>
                  <button
                    className="dinvoice1"
                    onClick={() => {
                      redirectToDetail(
                        data?.temp_request_data &&
                        JSON.parse(data?.temp_request_data)?.resource_id
                      );
                    }}
                  >
                    Go to Resource
                  </button>
                </div>
              </div>
            )}





            {data?.payment_for === "book_session" && otherData?.length > 0 ? (
              <div className="">
                <h2 style={{ color: "green", fontSize: "26px", textAlign: "center", margin: "50px 0px -50px 0px" }}>{localStorage.getItem("zeroPrice") === "yes" ? "Free session(s) booked successfully." : "Payment Successful"} </h2>
                <div className="row purchase-card-content">
                  <div className={`${(localStorage.getItem("zeroPrice") === "no") ? "col-lg-8" : "col-lg-12"}`}>
                    <div className="table-div">
                      <table>
                        <tr>
                          <th colSpan="4">
                            <div className="firstcol" > </div>
                            <div className="pati">
                              <h1>Details</h1>
                            </div>
                          </th>

                          {(localStorage.getItem("zeroPrice") === "no") && <th>Price</th>}
                          {(localStorage.getItem("zeroPrice") === "no") && <th>Total</th>}
                        </tr>
                        {JSON.parse(data?.temp_request_data)?.scheduleData?.map((v, i) => (
                          <>
                            <tr>
                              <td colSpan="4" className="marge-td vertalign">
                                <div className="tray">
                                  <div>
                                    <img
                                      src={
                                        otherData?.find(
                                          (p) => p?.scheduleId === v?.scheduleId
                                        )?.image
                                      }
                                      alt="user"
                                    ></img>
                                    <br></br>
                                  </div>
                                  <div className="all-set">
                                    <div className="row">
                                      <div className="col-sm-6 col-md-6 col-lg-6 ">
                                        <div style={{ textAlign: "left" }}>
                                          <h3>
                                            {
                                              otherData?.find(
                                                (p) => p?.scheduleId === v?.scheduleId
                                              )?.mentor
                                            }
                                          </h3>

                                          <h3>
                                            {
                                              otherData?.find(
                                                (p) => p?.scheduleId === v?.scheduleId
                                              )?.resource
                                            }
                                          </h3>
                                        </div>
                                      </div>
                                      <div className=" col-sm-6 col-md-6 col-lg-6">
                                        <div style={{ textAlign: "left" }}>
                                          <h3>
                                            {
                                              otherData?.find(
                                                (p) => p?.scheduleId === v?.scheduleId
                                              )?.duration
                                            }{" "}
                                            mins
                                          </h3>
                                          <h3>
                                            {otherData
                                              ?.find((p) => p?.scheduleId === v?.scheduleId)
                                              ?.child?.join(", ")}
                                          </h3>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              {(localStorage.getItem("zeroPrice") === "no") && <td>
                                $
                                {parseInt(
                                  otherData?.find((p) => p?.scheduleId === v?.scheduleId)
                                    ?.price
                                )?.toFixed(2)}
                              </td>}
                              {(localStorage.getItem("zeroPrice") === "no") && <td>
                                $
                                {(
                                  parseInt(
                                    otherData?.find(
                                      (p) => p?.scheduleId === v?.scheduleId
                                    )?.price
                                  ) * v?.session_date_time?.length
                                )?.toFixed(2)}
                              </td>}
                            </tr>
                            <tr>
                              <td colspan="5" style={{ height: "10px", paddingLeft: "0px 15px" }}>
                                <div className="selected-time">
                                  <Accordion>
                                    <AccordionItem >
                                      {({ open }) => (
                                        <>
                                          <AccordionHeader className={`accordion-title ${open ? 'accordion-active' : ''}`}>
                                            <button
                                              className="conti-btn2"
                                            >View Selected Date(s)/Time(s)</button>
                                          </AccordionHeader>
                                          <AccordionBody>
                                            {v?.session_date_time?.map((k) => (
                                              <div className="date-time my-3">
                                                <h2>{moment(k?.user_session_date).format("MM-DD-YYYY") ?? moment(k?.schedule_date).format("MM-DD-YYYY")}</h2>
                                                <span>{moment(k?.user_session_time, "h:mm:ss A").format("hh:mm A") ?? moment(k?.user_session_time, "h:mm:ss A").format("hh:mm A")}</span>&nbsp;{"-"}&nbsp;<span className="ms-0 mr-10">{moment(k?.user_session_end_time, "h:mm:ss A").format("hh:mm A") ?? moment(k?.user_session_end_time, "h:mm:ss A").format("hh:mm A")}</span>&nbsp; ({payLoad})
                                              </div>
                                            ))}
                                          </AccordionBody>
                                        </>
                                      )}
                                    </AccordionItem>
                                  </Accordion>
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                      </table>
                    </div>
                  </div>
                  {
                    localStorage.getItem("zeroPrice") === "no" && <div className='col-lg-3'>
                      <div className='card-right-container'>
                        <h3 className="registered"> <span><i className="fa fa-credit-card"></i></span>Payment Summary</h3>
                        <div className="subtitle-main">
                          <div className="titlesub">
                            <label>Sub Total</label>
                            <span>${data?.total_amount?.toFixed(2)}</span>
                          </div>
                          <div className="tax">
                            <label>Tax</label>
                            <span>${data?.tax_amount?.toFixed(2)}</span>
                          </div>
                        </div>
                      </div>
                      <div className="totaal">
                        <h3><span className="totle">Grand Total</span>${data?.total_amount?.toFixed(2)}</h3>
                      </div>
                      <div className='confirm-order-bttn'>
                        <button className="cartinvoicedownload" onClick={downloadInvoice}>Download Invoice</button>
                        <button className="clear-shopping clear-shopping-success mt-4" onClick={() => navigate("/session-history")}>View Booking</button>
                      </div>
                    </div>
                  }
                </div>
              </div>

            ) : redirect()}
            {isRePayment === true && (<>
              <h2 className="failed" style={{ color: "green" }}>Your card details has been updated successfully</h2>
            </>)}
          </>
        )}
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="text-center"
              style={{ color: "#2D3134", fontSize: "30px", lineHeight: "36px" }}
            >
              Your card will be charged approximately 72 hours before your scheduled booking.
            </Typography>
            <Typography
              id="modal-modal-description"
              sx={{ mt: 3 }}
              className="d-flex justify-content-center gap-4"
            >
              <button
                className="child-save"
                type="button"
                onClick={() => { setOpen(false) }}
              >
                Ok
              </button>{" "}

            </Typography>
          </Box>
        </Modal>
      </div>
    </>
  );
}
export default SubscriptionVerify;
